<template>
    <el-dialog
            title="导入订单数据"
            :close-on-click-modal="false"
            @close="closeHandle"
            append-to-body
            :visible.sync="visible">
        <el-upload
                drag
                action="13"
                :http-request="uploadExcel"
                :before-upload="beforeUploadHandle"
                multiple
                :file-list="fileList"
                :show-file-list="true"
                style="text-align: center;margin-top: 30px">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只支持excel！</div>
        </el-upload>
        <message-list :messageList="messageList" />
    </el-dialog>
</template>

<script>
    import {importExcel} from '@/api/modules/sampleorder'
    import messageList from '../../components/messageList.vue'
    export default {
        components: { messageList },
        data() {
            return {
                visible: false,
                successNum: 0,
                fileList: [],
                messageList: []
            };
        },
        methods: {
            init(id) {
                this.visible = true;
                this.messageList = []
            },
            // 上传之前
            beforeUploadHandle(file) {
                const isExcel = file.name.toString().indexOf(".xls") >0 || file.name.toString().indexOf(".xlsx") >0 ;
                const isLt10M = file.size / 1024 / 1024 < 5;
                if (!isExcel) {
                    this.$message.error("请上传文件格式不正确!");
                }
                if (!isLt10M) {
                    this.$message.error("上传文件大小不能超过 5MB!");
                }
                return isExcel && isLt10M;
            },
            // 弹窗关闭时
            closeHandle() {
                this.fileList = [];
                this.$emit("refreshDataList");
            },
            //upload Excel
            uploadExcel(item){
                let importData = new FormData();
                importData.append("file", item.file);
                importExcel(importData).then(({ data }) => {
                    if (data && data.msgStr) { 
                            const blob = new Blob([data.msgStr],{type: "text/plain"});
                            
                            const fileName = '订单导入信息汇总.txt';//下载文件名称
                            const elink = document.createElement('a');
                            elink.download = fileName;
                            elink.style.display = 'none';
                            elink.href = URL.createObjectURL(blob);
                            document.body.appendChild(elink);
                            elink.click();
                            URL.revokeObjectURL(elink.href); // 释放URL 对象
                            document.body.removeChild(elink);
                    }
                    if (data && data.code === 0) {
                        this.$message({
                            message:  "数据导入成功",
                            type: "success",
                            duration: 1500,
                            onClose: () => {
                                this.visible = false
                                this.$emit("refreshDataList");
                            }
                        });
                        
                    } else {
                        
                        return this.$alert(data.msg, "", {
                            confirmButtonText: "确定",
                            callback: action => {
                            }
                        });
                    }
                });
            }

        }
    };
</script>
