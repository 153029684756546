<template>
    <el-dialog
        title="添加底粉"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        width="680px"
        @close="closeDialogVisible">
        <div class="selectBasePowder">
            <div>
                <div style="visibility:hidden">-</div>
                <el-select v-model="type" @change="change" class="custom-select">
                    <el-option label="成品粉" value="1"></el-option>
                    <el-option label="近期订单" value="2"></el-option>
                </el-select>
            </div>
            <div v-if="type === '1'">
                <div>产品号</div>
                <el-select v-model="product.id" @change="productNoChange" filterable remote reserve-keyword
                    placeholder="" :remote-method="remoteMethod" class="custom-select">
                    <el-option v-for="(item, index) in productNoList" :key="index" :label="item.productNo" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div v-if="type === '2'">
                <div>订单号</div>
                <!--测试数据6980011金属粉没底粉-->
                <el-select v-model="orderNo" @change="orderNoChange" filterable remote reserve-keyword
                    placeholder="" :remote-method="getOrderNoList" class="custom-select">
                    <el-option v-for="(item, index) in orderList" :key="index" :label="item.orderNo" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div v-if="type === '2'">
                <div>配方单</div>
                <el-select v-model="productInfoChildren" @change="changeProductInfoChildren" class="custom-select">
                    <el-option v-for="(item, index) in productInfoChildrenArr" :key="index" :label="item.sampleProductNo" :value="item.id"></el-option>
                </el-select>
            </div>
            <div v-if="type === '1'">
                <div style="visibility:hidden">-</div>
                <el-button type="primary" @click="submit()">确定</el-button>
            </div>
        </div>
        <div class="selectBasePowder_b" v-if="productInfoChildren && productInfo && productInfo.id">
            <div>
                <div>{{productClassify === '0' ? '金属粉' : '普通粉'}}</div>
                <div>
                    <el-radio v-model="selectVal" :disabled="productInfo.selectable === false ? true : false" label="one"><span style="visibility:hidden">-</span></el-radio>
                </div>
                <div v-if="productClassify === '0'" class="item_content">
                    <div>{{productInfo.sampleProductNo}}</div>
                </div>
                <div v-else class="item_content">
                    <div>{{productInfo.sampleProductNo}}</div>
                    <div v-for="item in productInfo.children" :key="item.id">{{item.sampleProductNo}}</div>
                </div>
            </div>
            <div v-if="productClassify === '0' && productInfo.children.length">
                <div>底粉</div>
                <div>
                    <el-radio v-model="selectVal" label="two"><span style="visibility:hidden">-</span></el-radio>
                </div>
                <div class="item_content">
                    <div v-for="item in productInfo.children" :key="item.id">{{item.sampleProductNo}}</div>
                </div>
            </div>
            <div>
                <div style="visibility:hidden">-</div>
                <div style="visibility:hidden">-</div>
                <el-button type="primary" @click="submitOrder()">确定</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { finishedProductRecipe, recentlyOrderRecipe, getOrderNoList } from "@/api/modules/sampleorder"
export default {
    data() {
        return {
            dialogVisible: false,
            type: '1',
            product: {
                no: '',
                id: ''
            }, // 成品粉产品号
            productNoList: [],
            orderNo: '',
            orderList: [],
            productInfoChildren: '',
            productClassify: '',
            productInfoChildrenArr: [],
            productInfo: {},
            selectVal: '',
        };
    },
    methods: {
        init(id) {
        this.dialogVisible = true;
        this.type = '1'
        },
        remoteMethod(query) {
            var params = {
                productNo: query
            }
            if (query.length >= 7) {
                finishedProductRecipe(params).then(({ data }) => {
                    if (data && data.code === 0) {
                        this.productNoList = data.productList
                    }
                })
            }
        },
        getOrderNoList(query) {
            var params = {
                orderNo: query
            }
            if (query.length >= 6) {
                getOrderNoList(params).then(({ data }) => {
                    if (data && data.code === 0) {
                        this.orderList = data.orderList
                    }
                })
            }
        },
        closeDialogVisible() {
            this.dialogVisible = false;
            this.orderNo = ''
            this.product = {
                no: '',
                id: ''
            }
            this.productInfoChildren = ''
            this.orderList = []
            this.productNoList = []
            this.productInfoChildrenArr = []
            this.productClassify = '';
            this.productInfo = {}
            this.selectVal = ''
        },
        // 第一个选择框
        change() {
            this.orderNo = ''
            this.product = {
                no: '',
                id: ''
            }
            this.productInfoChildren = ''
            this.orderList = []
            this.productNoList = []
            this.productInfoChildrenArr = []
            this.productClassify = '';
            this.productInfo = {}
            this.selectVal = ''
        },
        productNoChange(e) {
            this.productNoList.map((v) => {
                if (v.id === e) {
                    this.product.no = v.productNo
                }
            })
        },
        orderNoChange(val) {
            this.productInfoChildren = ''
            this.productInfoChildrenArr = []
            this.productClassify = '';
            this.productInfo = {}
            this.selectVal = '',
            setTimeout(() => {
                this.recentlyOrderRecipe()
            }, 10);
        },
        recentlyOrderRecipe() {
            var params = {
                orderId: this.orderNo
            }
            this.orderNo && recentlyOrderRecipe(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.productInfoChildrenArr = data.productInfo
                    this.productClassify = data.productClassify
                }
            })
        },
        changeProductInfoChildren(e) {
            this.productInfoChildrenArr.map((v) => {
                if (v.id === e) {
                    this.productInfo = v
                    if (this.productClassify === '1') {
                        this.selectVal = "one"
                    } else {
                        if (this.productInfo && this.productInfo.children && this.productInfo.children.length) {
                            this.selectVal = "two"
                        } else {
                            this.selectVal = "one"
                        }
                        
                    }
                }
            })
        },
        submit() {
            if (this.product.no) {
                var arr = [this.product]
                this.$emit("addData", JSON.stringify(arr));
                this.closeDialogVisible();
            }
        },
        submitOrder() {
            if (['one', 'two'].includes(this.selectVal) && this.productInfoChildren && this.productInfo && this.productInfo.id) {
                var arr = [];
                if (this.productClassify === '0') {
                    if (this.selectVal === 'one') {
                        var obj0 = {
                            id: this.productInfo.id,
                            no: this.productInfo.sampleProductNo
                        }
                        arr.push(obj0)
                    } else {
                        this.productInfo.children.map((v) => {
                            var obj_ = {
                                id: v.id,
                                no: v.sampleProductNo
                            }
                            arr.push(obj_)
                        })
                    }
                } else {
                    var obj1 = {
                        id: this.productInfo.id,
                        no: this.productInfo.sampleProductNo
                    }
                    arr.push(obj1)
                }
                this.$emit("addData", JSON.stringify(arr));
                this.closeDialogVisible();
            }
        }
    },
};
</script>
<style lang="scss" scope>
.selectBasePowder{
    display: flex;
    >div{
        width: 200px;
        box-sizing: border-box;
        margin-right: 10px;
        >div:nth-child(1) {
            margin-bottom: 10px;
            text-align: center;
        }
    }
}
.selectBasePowder_b {
    display: flex;
    >div{
        width: 200px;
        box-sizing: border-box;
        margin-right: 10px;
        >div:nth-child(1) {
            text-align: center;
            height: 40px;
            line-height: 40px;
            margin-top: 10px;
        }
        >div:nth-child(2) {
            text-align: center;
            margin-bottom: 10px;
        }
        .item_content {
            >div{
                width: 200px;
                height: 40px;
                margin-bottom: 10px;
                border: 1px solid #dcdfe6;
                color: #606266;
                line-height: 40px;
                text-align:center;
                font-size: inherit;
            }
            >div:last-child {
                margin-bottom: 0px;
            }
        }
    }
}
</style>