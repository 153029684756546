<template>
    <el-dialog title="编辑参考配方" class="referenceRecipe_old" :close-on-click-modal="false" :visible.sync="visible" width="1800px">
        <div class="header">
            <div class="search-recipe-text">
                <span class="span-text">在配方库中搜索</span>
            </div>
            <div class="smaple-order-info">
                <span class="span-info">{{ referenceRecipeName }}</span>
            </div>
        </div>
        <div class="container">
            <div class="recipe-type">
                <div class="button-row">
                    <el-button :class="recipeFlag == '1' ? 'batton-1 custom-button' : 'custom-button'" v-model="recipeFlag"
                        @click="custum()" :disabled="isDisabled">自定义编辑</el-button>
                </div>
                <div class="button-row">
                    <el-button :class="recipeFlag == '2' ? 'batton-1 custom-button' : 'custom-button'" v-model="recipeFlag"
                        @click="normal()" :disabled="isDisabled">标准配方库</el-button>
                </div>
                <div class="button-row">
                    <el-button :class="recipeFlag == '3' ? 'batton-1 custom-button' : 'custom-button'" v-model="recipeFlag"
                        @click="finished()" :disabled="isDisabled">成品配方库</el-button>
                </div>
            </div>
            <div class="select-recipe">
                <el-select v-model="searchValue" @change="valueChange" filterable remote reserve-keyword
                    placeholder="系列/产品/订单/名称" :remote-method="remoteMethod" :disabled="isDisabled" :loading="loading" class="custom-select">
                    <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item">
                    </el-option>
                </el-select>
            </div>

            <div class="recipe-info">
                <div class="recipe-basic-info">

                    <div class="property-row">
                        <span class="property-label">来源：</span>
                        <span class="property-value">{{ source }}</span>
                        <span class="property-label">光泽：</span>
                        <span class="property-value">{{ gloss }}</span>
                    </div>

                    <div class="property-row">
                        <span class="property-label">分类：</span>
                        <el-select v-model="componentValue" size="mini" :disabled="disabled"
                            @change="componentValueChange()">
                            <el-option label="单组份" value="1"></el-option>
                            <el-option label="双组份" value="2"></el-option>
                            <el-option label="成品底粉" value="3"></el-option>
                        </el-select>
                        <span class="property-label">行业：</span>
                        <span class="property-value">{{ industry }}</span>
                    </div>


                </div>
                <div class="material-data">
                    <div class="material-info" v-show="isShowA">

                        <!-- 操作区域 -->
                        <div class="operation">
                            <el-button @click="pasteFromSAP">黏贴SAP</el-button>
                            <el-button @click="deleteSelected" v-show="baserecipe">底粉</el-button>
                            <el-button @click="deleteSelected">删除</el-button>
                        </div>

                        <!-- 组件信息和总重量展示 -->
                        <div class="component-info">
                            <div class="component-info-item">
                                <span>组分信息: {{ componentA }}</span>
                                <span>总重量: {{ totalWeightA }} kg</span>
                            </div>
                        </div>

                        <!-- 配方表格 -->
                        <el-table :data="recipeA.mater" border style="width: 100%" height="calc(100% - 94px)">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column label="编号" prop="materialNo" width="150">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.isEdit">
                                        {{ scope.row.materialNo }}
                                    </div>
                                    <el-input v-model="scope.row.materialNo" placeholder="物料" size="small"
                                        v-else></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="重量" prop="materialValue" width="120">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.isEdit">
                                        {{ scope.row.materialValue }}
                                    </div>
                                    <el-input v-model="scope.row.materialValue" placeholder="g" size="small"
                                        v-else></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-button type="small" @click="btnClick(scope.row)">补</el-button>
                                </template>
                            </el-table-column>
                        </el-table>


                        <!-- 添加物料按钮 -->
                        <el-button class="addBtn" @click="addRowsA()" :disabled="isDisabled">+</el-button>
                    </div>
                    <div class="material-info" v-show="isShowB">

                        <!-- 操作区域 -->
                        <div class="operation">
                            <el-button @click="pasteFromSAP">黏贴SAP</el-button>
                            <el-button @click="deleteSelected">删除</el-button>
                        </div>

                        <!-- 组件信息和总重量展示 -->
                        <div class="component-info">
                            <div class="component-info-item">
                                <span>组分信息: {{ componentB }}</span>
                                <span>总重量: {{ totalWeightB }} kg</span>
                            </div>
                        </div>

                        <!-- 配方表格 -->
                        <el-table :data="recipeB.mater" border style="width: 100%" height="calc(100% - 94px)">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column label="编号" prop="materialNo" width="150">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.isEdit">
                                        {{ scope.row.materialNo }}
                                    </div>
                                    <el-input v-model="scope.row.materialNo" placeholder="物料" size="small"
                                        v-else></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="重量" prop="materialValue" width="120">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.isEdit">
                                        {{ scope.row.materialValue }}
                                    </div>
                                    <el-input v-model="scope.row.materialValue" placeholder="g" size="small"
                                        v-else></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-button type="small" @click="btnClick(scope.row)">补</el-button>
                                </template>
                            </el-table-column>
                        </el-table>


                        <!-- 添加物料按钮 -->
                        <el-button class="addBtn" @click="addRowsB()" :disabled="isDisabled">+</el-button>

                    </div>
                    <div class="material-info" v-show="isShowC">

                        <!-- 操作区域 -->
                        <div class="operation">
                            <el-button @click="pasteFromSAP">黏贴SAP</el-button>
                            <el-button @click="deleteSelected">删除</el-button>
                        </div>

                        <!-- 组件信息和总重量展示 -->
                        <div class="component-info">
                            <div class="component-info-item">
                                <span>组分信息: {{ componentC }}</span>
                                <span>总重量: {{ totalWeightC }} kg</span>
                            </div>
                        </div>

                        <!-- 配方表格 -->
                        <el-table :data="recipeC.mater" border style="width: 100%" height="calc(100% - 94px)">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column label="编号" prop="materialNo" width="150">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.isEdit">
                                        {{ scope.row.materialNo }}
                                    </div>
                                    <el-input v-model="scope.row.materialNo" placeholder="物料" size="small"
                                        v-else></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="重量" prop="materialValue" width="120">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.isEdit">
                                        {{ scope.row.materialValue }}
                                    </div>
                                    <el-input v-model="scope.row.materialValue" placeholder="g" size="small"
                                        v-else></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-button type="small" @click="btnClick(scope.row)">补</el-button>
                                </template>
                            </el-table-column>
                        </el-table>


                        <!-- 添加物料按钮 -->
                        <el-button class="addBtn" @click="addRowsC()" :disabled="isDisabled">+</el-button>
                    </div>
                </div>

                <div class="inspection-plan" v-show="recipeInfo">
                    <p class="title">检验计划</p>
                    <div class="plan_content">
                        <div v-for="(item, index) in inspectionPlanData" :key="index" class="plan_wrap">
                            <span v-if="!item.isEdit">{{ item.name }}</span>
                            <el-input v-else v-model="item.name" size="mini"></el-input>
                            <span v-if="!item.isEdit">{{ item.data }} </span>
                            <el-input v-else class="plan_row" v-model="item.data" size="mini"></el-input>
                            <a style="display: inline-block;width: 22px;"><i class="el-icon-edit"
                                    style="font-size: 18px;line-height: 38px;" @click="planEdit(item)"></i></a>

                        </div>
                    </div>
                </div>

                <div class="recipe-explain" v-show="recipeInfo">
                    <div class="explain">说明:</div>
                    <el-input type="textarea" v-model="explainData" :rows="10" :disabled="true"></el-input>
                </div>

            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close()" size="small">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">确定</el-button>
        </span>
    </el-dialog>
</template>
  
<script>
import {
    queryRecipeData,
    querySampleOrderInfo
} from "@/api/modules/sampleorder";
import { saveReferenceRecipe, queryRecipeInfo } from "@/api/modules/recipe"
export default {
    data() {
        return {
            visible: false,
            dataLoading: false,
            isDisabled:false,
            recipeInfo: false,
            options: [],
            searchValue: '',
            list: [],
            source: '',
            gloss: '',
            industry: '-',
            loading: false,
            recipeFlag: '0',
            isShowA: false,
            isShowB: false,
            isShowC: false,
            baserecipe: false,
            disabled: true,
            referenceRecipeName: '',
            sampleOrderId: '',
            //1.普通粉 2.金属粉
            isMetalPowder: "",
            resultMap: {},
            componentValue: '',
            recipeA: {
                mater: []
            },
            recipeB: {
                mater: []
            },
            resultData: [],
            recipeC: {
                mater: []
            },
            dialogVisible: false,
            newMaterial: {
                materialNo: "",
                materialValue: ""
            },
            componentA: "", // 从接口返回的组分信息
            totalWeightA: 0, // 所有物料的总重量
            componentB: "A",
            totalWeightB: 0,
            componentC: "B",
            totalWeightC: 0,
            inspectionPlanData: [],//检验计划
            explainData: '',//说明
        };
    },
    created() {
        this.componentValueChange()
    },
    methods: {
        init(id, existRecipe) {
            console.log(existRecipe, "existRecipe")
            this.sampleOrderId = id
            this.visible = true;
            var param = {
                orderId: id
            }
            querySampleOrderInfo(param).then((res) => {
                if (res.data.code === 0) {
                    // this.source = res.data.querySampleOrderInfo.sampleOrderNo
                    this.gloss = res.data.querySampleOrderInfo.gloss
                    // this.gloss = '5-10'
                    //分类
                    // this.componentValue = '2'
                    //行业
                    this.industry = res.data.querySampleOrderInfo.industry
                    //是金属粉0，还是普通粉1
                    this.isMetalPowder = res.data.querySampleOrderInfo.productCartegry
                    this.referenceRecipeName = res.data.querySampleOrderInfo.referenceRecipeName
                }

            })
            //如果existRecipe 说明已经分配参考配方，直接反显，并可不能修改
            if (existRecipe === '0') {
                queryRecipeInfo(param).then((res) => {
                    if (res.data.code === 0) {
                        this.isDisabled = true
                        this.dataLoading = true
                        //单组份，双组份
                        this.componentValue = res.data.recipeVo.size;
                        //备注
                        this.explainData = res.data.recipeVo.remarks;
                        //recipeA表示主配方单
                        if (res.data.recipeVo.materialsVosMap.A) {
                            this.recipeA.mater = res.data.recipeVo.materialsVosMap.A;
                            this.isShowA = true
                        }
                        if (res.data.recipeVo.materialsVosMap.B) {
                            this.recipeB.mater = res.data.recipeVo.materialsVosMap.B;
                            this.isShowB = true
                        }
                        if (res.data.recipeVo.materialsVosMap.C) {
                            this.recipeC.mater = res.data.recipeVo.materialsVosMap.C;
                            this.isShowC = true

                        }
                        this.recipeInfo = true
                        this.inspectionPlanData = res.data.recipeVo.planList.map((item) => {
                            const keys = Object.keys(item);
                            return {
                                name: keys[0],
                                data: item[keys[0]],
                                isEdit: false,
                            }
                        });
                    }


                })


            }
        },
        //点击自定义配方
        custum() {
            this.recipeFlag = '1'
            this.recipeInfo = true
            this.disabled = false
            this.recipeA.mater = []
            this.recipeB.mater = []
            this.recipeC.mater = []

        },
        //点击标准配方
        normal() {
            this.recipeFlag = '2'
            this.recipeInfo = true
            this.disabled = true
            this.recipeA.mater = []
            this.recipeB.mater = []
            this.recipeC.mater = []

        },

        //点击成品配方
        finished() {
            this.recipeFlag = '3'
            this.recipeInfo = true
            this.disabled = true
            this.recipeA.mater = []
            this.recipeB.mater = []
            this.recipeC.mater = []

        },


        close() {
            // this.$refs["dataForm"].resetFields();
            this.visible = false;
        },

        remoteMethod(query) {
            var params = {
                recipeFlag: this.recipeFlag,
                searchValue: query
            }
            if ((query.length >= 3 && (this.recipeFlag === '2') || (query.length >=7 && this.recipeFlag === '3'))) {
                queryRecipeData({ params }).then(
                    ({ data }) => {
                        if (data && data.code === 0) {
                            //下拉选项
                            this.options = data.options
                            if (data.recipeVoList[0]) {
                                //单组份，双组份
                                this.componentValue = data.recipeVoList[0].size;
                                //备注
                                this.explainData = data.recipeVoList[0].remarks;

                                //recipeA表示主配方单
                                if (data.recipeVoList[0].materialsVosMap.C) {
                                    this.recipeA.mater = data.recipeVoList[0].materialsVosMap.C;
                                }
                                if (data.recipeVoList[0].materialsVosMap.A) {
                                    this.recipeB.mater = data.recipeVoList[0].materialsVosMap.A;
                                }

                                if (data.recipeVoList[0].materialsVosMap.B) {
                                    this.recipeC.mater = data.recipeVoList[0].materialsVosMap.B;
                                }
                                this.inspectionPlanData = data.recipeVoList[0].planList.map((item) => {
                                    const keys = Object.keys(item);
                                    return {
                                        name: keys[0],
                                        data: item[keys[0]],
                                        isEdit: false,
                                    }
                                });
                                this.source =data.recipeVoList[0].option
                            }
                        }
                    }
                );
            }
        },
        valueChange(row) {
            this.componentValueChange()
        },
        //普通粉单组份，普通粉双组份，金属粉双组份
        componentValueChange() {
            if (this.isMetalPowder === "1" && this.componentValue === "1") {
                this.isShowA = false
                this.isShowB = true
                this.isShowC = false

            } else if (this.isMetalPowder === "1" && this.componentValue === "2") {
                this.isShowA = false
                this.isShowB = true
                this.isShowC = true
            } else if (this.isMetalPowder === "0" && this.componentValue === "2") {
                this.isShowA = true
                this.isShowB = true
                this.isShowC = true
            } else if (this.isMetalPowder === "0" && this.componentValue === "1") {
                this.isShowA = true
                this.isShowB = true
                this.isShowC = false
            }
            if (this.isMetalPowder === "0" && this.componentValue === '3' && this.recipeFlag === '1') {
                this.baserecipe = true
                this.isShowA = true
            }
            if (this.isMetalPowder === "0" && this.componentValue === '2' && this.recipeFlag === '1') {
                this.baserecipe = true
                this.isShowA = true
                this.isShowB = true
                this.isShowC = true
            }

        },

        // 添加新行
        addRowsA() {
            this.recipeA.mater.push({ materialNo: '', materialValue: '', isEdit: true });
        },
        // 添加新行
        addRowsB() {
            this.recipeB.mater.push({ materialNo: '', materialValue: '', isEdit: true });
        },

        // 添加新行
        addRowsC() {
            this.recipeC.mater.push({ materialNo: '', materialValue: '', isEdit: true });
        },

        btnClick(row) {

        },
        dataFormSubmit() {
            const formData = {
                materialsVosMap: {
                    "A": this.recipeA.mater,
                    "B": this.recipeB.mater,
                    "C": this.recipeC.mater,
                },
                planList: this.inspectionPlanData,
                // remarks: this.explainData,
                sampleOrderId: this.sampleOrderId,
                productCartegry: this.isMetalPowder

            }
            saveReferenceRecipe(formData).then((res) => {
                //TODO 校验无聊数据全部填写了建议称重

                if (res && res.data.code === 0) {
                    this.$message({
                        message: "下发参考配方成功",
                        type: "success",
                        duration: 1500,
                        onClose: () => {
                            this.visible = false;
                            this.dataLoading = false;
                            this.$emit("refreshDataList");
                        },
                    });
                } else {
                    this.$message.error(res.data.msg);
                    this.dataLoading = false;
                }
                this.clearInfo()
            })
        },
        planEdit(item) {
            item.isEdit = true
        },
        confirmAdd() {
            // 将新物料数据添加到配方中
            this.recipe.mater.push({
                materialCode: this.newMaterial.materialCode,
                materialWeight: this.newMaterial.materialWeight
            });
            // 更新总重量
            this.calculateTotalWeight();
            // 清空输入框
            this.newMaterial.materialCode = "";
            this.newMaterial.materialWeight = "";
            // 关闭对话框
            this.dialogVisible = false;
        },
        pasteFromSAP() {
            // 实现黏贴SAP的逻辑
            // ...
        },
        deleteSelected() {
            // 删除选中的行
            const selectedRows = this.$refs.selectionTable.selection;
            if (selectedRows.length > 0) {
                this.recipe.mater = this.recipe.mater.filter(
                    (item) => !selectedRows.includes(item)
                );
                // 更新总重量
                this.calculateTotalWeight();
            }
        },
        calculateTotalWeight() {
            // 计算总重量
            this.totalWeight = this.recipe.mater.reduce(
                (total, item) => total + parseFloat(item.materialWeight),
                0
            );
        },
        clearInfo() {
            this.recipeA.mater = [],
                this.recipeB.mater = [],
                this.recipeC.mater = []
            this.isShowA = false
            this.isShowB = false
            this.isShowC = false
        }

    },




};
</script>
<style lang="scss" scope>
.referenceRecipe_old {
    .header {
        width: 1461px;
        height: 40px;
        display: flex;
        flex-direction: row;
        /* 默认水平方向排列 */
    }

    .custom-button {
        /* 定义按钮的默认样式 */
        background-color: #bdc3c7;
        color: #FFFFFF;
        border: none;
    }

    .custom-button:active {
        /* 定义按钮在点击时的样式 */
        background-color: #909399;
    }

    .batton-1 {
        background: #1F1C2C;
        background: -webkit-linear-gradient(to right, #928DAB, #1F1C2C);
        background: linear-gradient(to right, #928DAB, #1F1C2C);

    }

    .container {
        display: flex;
        /* 使用 Flex 布局 */
        justify-content: space-between;
        /* 将内容分布在主轴上，以便它们位于同一水平线上 */
        align-items: flex-start;
        /* 顶部对齐 */
        width: 100%;
        /* 设置容器宽度 */
        height: 1500px;
        /* 设置容器高度 */
        padding: 10px;
        /* 设置内边距 */
    }

    .recipe-type {
        height: 150px;
        width: 120px;
        border: 1px solid #ddd;
        display: flex;
        flex-direction: column;
        align-items: center;

        .button-row {
            margin: 5px 0;
        }
    }

    .select-recipe {
        height: 40px;
        width: 220px;
        margin-left: -40px;
        border: 1px solid #ddd;
    }

    .recipe-info {
        height: 1450px;
        width: 1300px;
        border: 1px solid #ddd;
        padding: 10px;
    }

    .custom-select {
        width: 230px;
    }

    .search-recipe-text {
        width: 200px;
        height: 40px;
        display: flex;
        justify-content: center;
        /* 水平居中 */
        align-items: center;
        /* 垂直居中 */
        text-align: center;
        /* 文本居中对齐 */
        line-height: 80px;
        /* 垂直居中文本 */
        margin: 0 200px;

        /* 设置div之间的间距 */
        .span-text {
            text-align: center;
            /* 文本水平居中 */
            display: inline-block;
            /* 使文本元素可以垂直居中 */
        }
    }

    .smaple-order-info {
        width: 200px;
        height: 40px;
        display: flex;
        justify-content: center;
        /* 水平居中 */
        align-items: center;
        /* 垂直居中 */
        text-align: center;
        /* 文本居中对齐 */
        line-height: 80px;
        /* 垂直居中文本 */
        margin: 0 200px;

        /* 设置div之间的间距 */
        .span-info {
            text-align: center;
            /* 文本水平居中 */
            display: inline-block;
            /* 使文本元素可以垂直居中 */
        }
    }

    .recipe-basic-info {
        width: 900px;
        height: 40px;

    }

    .plan_wrap {
        display: flex;
    }

    .plan_wrap input {
        height: 38px !important;
        line-height: 38px;

    }

    .plan_wrap .plan_row input {
        border-right: none;
    }

    .property-row {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    .el-select {
        margin-right: 10px;
        /* 设置选择框与其他内容之间的右边距 */
    }

    .property-label {
        font-weight: bold;
        margin-right: 10px;
        margin-left: 213px;
    }

    .property-value {
        flex-grow: 1;
        /* 如果属性值过长，可以添加样式以控制文本溢出或换行 */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .material-data {
        width: 1280px;
        height: 550px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .material-info {
        position: relative;
        width: 600px;
        height: 500px;
        margin: 0 10px;

    }

    .inspection-plan {
        width: 600px;
        height: 500px;
        margin-top: 20px;
        text-align: center;
        border: 1px solid #DCDFE6;

    }

    .recipe-explain {
        width: 900px;
        height: 200px;
        margin-top: 20px;
        /* background-color: darkcyan; */

    }

    .operation {
        display: flex;
        justify-content: space-between;
        padding: 3px;
        border: 1px solid #DCDFE6;
        border-radius: 6px 6px 0 0;
    }

    .component-info {
        padding: 10px 3px;
        border-left: 1px solid #DCDFE6;
        border-right: 1px solid #DCDFE6;
    }

    .component-info-item {
        display: flex;
        justify-content: space-between;
    }

    .addBtn {
        position: absolute;
        width: 100%;
        border-top: none;
        border-radius: 0;
    }

    .addBtn span {
        font-size: 16px;
    }

    .inspection-plan .title {
        font-size: 16px;
        color: #000;
        border-bottom: 1px solid #DCDFE6;
        margin: 0;
        height: 38px;
        line-height: 38px;
    }

    .inspection-plan div span {
        display: inline-block;
        width: 50%;
        height: 38px;
        line-height: 38px;
        color: #606266;
        border-bottom: 1px solid #DCDFE6;
    }

    .inspection-plan .plan_content div:last-child span {
        border-bottom: none;
    }

    .inspection-plan div span:first-child {
        border-right: 1px solid #DCDFE6;
    }

    .inspection-plan .plan_content {
        height: calc(100% - 40px);
        overflow-y: scroll;
    }

    .plan_content span i {
        float: right;
        margin-top: 12px;
        margin-right: 7px;
    }
}
</style>
  