<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-table-column
      type="selection"
      width="55">
    </el-table-column>
      <el-form-item prop="orderNo" label="订单号">
        <el-input
          v-model="dataForm.orderNo"
          placeholder="订单号"
          size="small"
          clearable
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="States && States.length > 0" label="订单状态">
        <el-select v-model="dataForm.state" placeholder="订单状态" clearable>
          <el-option
            v-for="item in States"
            :key="item.stateKey"
            :label="item.stateValue"
            :value="item.stateKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单类型">
        <el-select
          v-model="dataForm.sampleType"
          placeholder="订单类型"
          clearable
        >
          <el-option
            v-for="item in sampleTypes"
            :key="item.sampleTypeKey"
            :label="item.sampleTypeValue"
            :value="item.sampleTypeKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="btnState0" label="打样状态">
        <el-select v-model="dataForm.state" placeholder="订单类型">
          <el-option value="0,6" label="所有"></el-option>
          <el-option value="0" label="未分配"></el-option>
          <el-option value="6" label="已分配"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属工厂" v-if="btnOpt">
        <el-select
          v-model="dataForm.factoryId"
          size="small"
          placeholder="请选择工厂"
        >
          <el-option label="全部" value="-1"></el-option>
          <el-option
            v-for="item in factoryList"
            :key="item.id"
            :label="item.factoryName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="创建日期"
        v-if="btnState0 || btnState1 || btnState2 || btnState3 || btnState4"
      >
        <div class="block">
          <el-date-picker
            v-model="dataForm.createDates"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="完成日期" v-if="btnOpt || btnState4">
        <div class="block">
          <el-date-picker
            v-model="dataForm.updateDates"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button
          type="primary"
          v-if="btnOpt || btnState0"
          @click="addOrUpdateHandle()"
          size="small"
          >新增</el-button
        >
        <el-button
          v-if="btnState4 && isAuth('sample:sampleorder:export')"
          type="primary"
          @click="importSampleorder()"
          :loading="importSampleorderval"
          size="small"
          :disabled = "this.selectData.length > 0 ? false :true"
          >导出配方</el-button
        >
        <el-button
          type="primary"
          v-if="btnOpt"
          @click="downImport()"
          size="small"
          >下载导入模板</el-button
        >
        <el-button
          type="primary"
          v-if="btnOpt"
          @click="importOrder()"
          size="small"
          >导入订单</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      :default-sort="{ prop: 'createDate', order: 'descending' }"
      @selection-change="selectionChangeHandle"
      @sort-change="sortChange"
      style="width: 100%"
    >

      <el-table-column type="selection" width="55" :selectable="selection => isCheckboxDisabled(selection)"> 
        <!-- <template slot-scope="scope">
        <el-checkbox
          v-model="scope.row.selected"
          :disabled="isCheckboxDisabled(scope.row)"
        ></el-checkbox>
      </template> -->
        
      </el-table-column>
      <el-table-column
        prop="sampleOrderNo"
        header-align="center"
        align="center"
        fixed="left"
        label="订单号"
      >
      </el-table-column>
      <el-table-column
        prop="orderSource"
        header-align="center"
        align="center"
        label="订单来源"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.orderSource == '0'">管理系统</span>
          <span
            v-if="scope.row.orderSource == '1' || scope.row.orderSource == '3'"
            >小程序</span
          >
          <span v-if="scope.row.orderSource == '2'">门户网站</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="productCartegry"
        header-align="center"
        align="center"
        label="产品类别"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.productCartegry == '0'">金属粉</span>
          <span v-if="scope.row.productCartegry == '1'">普通粉</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="sampleType"
        header-align="center"
        align="center"
        label="订单类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.sampleType == '0'">新产品打样</span>
          <span v-if="scope.row.sampleType == '1'">样粉样板申请</span>
          <span v-if="scope.row.sampleType == '2'">配方调整</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="modelSum"
        header-align="center"
        align="center"
        label="样板数量"
      >
      </el-table-column>
      <el-table-column
        prop="materialSum"
        header-align="center"
        align="center"
        label="样粉数量（KG）"
      >
      </el-table-column>
      <el-table-column
        v-if="btnOpt"
        prop="state"
        header-align="center"
        align="center"
        label="订单状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.state == '0'">未分配</span>
          <span v-if="scope.row.state == '1'">打样中</span>
          <span v-if="scope.row.state == '2'">待验证</span>
          <span v-if="scope.row.state == '3'">已完成</span>
          <span v-if="scope.row.state == '4'">验证驳回</span>
          <span v-if="scope.row.state == '5'">待结单</span>
          <span v-if="scope.row.state == '6'">待打样</span>
          <span v-if="scope.row.state == '7'">待自检</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="btnState0 || btnState1 || btnState2 || btnState3 || btnState4"
        prop="contactsName"
        header-align="center"
        align="center"
        label="客户"
      >
      </el-table-column>
      <el-table-column
        v-if="btnState0 || btnState1 || btnState2 || btnState3 || btnState4"
        prop="contactsLevel"
        header-align="center"
        align="center"
        width="100px;"
        :sortable="'custom'"
        label="客户级别"
      >
      </el-table-column>
      <el-table-column
        prop="deliverDate"
        header-align="center"
        :sortable="'custom'"
        align="center"
        width="100px;"
        label="交付日期"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.deliverDate | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="btnOpt"
        prop="createDate"
        header-align="center"
        align="center"
        :sortable="'custom'"
        width="100px;"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        v-if="btnState0 || btnState1 || btnState2 || btnState3 || btnState4"
        prop="createDate"
        header-align="center"
        align="center"
        :sortable="'custom'"
        width="100px;"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        v-if="btnState4"
        prop="updateDate"
        header-align="center"
        align="center"
        label="完成时间"
      >
        <template scope="scope">
          <p v-if="scope.row.state == '3'">{{ scope.row.updateDate }}</p>
        </template>
      </el-table-column>
      <el-table-column
        v-if="btnState0 || btnState1 || btnState4"
        prop="userName"
        header-align="center"
        align="center"
        label="打样员"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="180"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="
              btnState3 &&
              dataList.length > 0 &&
              scope.row.state == '5' &&
              isAuth('sample:sampleorder:statement')
            "
            type="text"
            size="small"
            @click="addOrUpdateHandleTo(scope.row.id, scope.row.sampleOrderNo)"
            >关闭</el-button
          >
          <el-button
            v-if="isAuth('sample:sampleorder:view')"
            type="text"
            size="small"
            @click="addOrUpdateHandleInfo(scope.row.id)"
            >查看</el-button
          >
          <el-button
            v-if="
              (btnState1 || btnState2 || btnState3 || btnState4) &&
              scope.row.state != 0 &&
              isAuth('sample:sampleorder:proofingList')
            "
            type="text"
            size="small"
            @click="SampleListHandle(scope.row.id, scope.row.cartegryNameS)"
            >打样单</el-button
          >
          <el-button
            v-if="
              (btnState1 || btnState2 || btnState3 || btnState4) &&
              scope.row.state != 0 &&
              isAuth('sample:sampleorder:myproofing')
            "
            type="text"
            size="small"
            @click="SampleListHandle(scope.row.id, scope.row.cartegryNameS)"
            >打样记录页面</el-button
          >
          <el-button
            v-if="
              btnState0 &&
              isAuth('sample:sampleorder:edit') &&
              scope.row.state != 3 &&
              scope.row.state != 5
            "
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            v-if="
              (scope.row.state == 0 ||
                scope.row.state == 1 ||
                scope.row.state == 6 ||
                scope.row.state == 7) &&
              isAuth('sample:sampleorder:delete')
            "
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
          <el-button
            v-if="isAuth('sample:sampleorder:checkColor')"
            type="text"
            size="small"
            @click="queryColor(scope.row)"
            >查色</el-button
          >
          <el-button
            v-if="
              btnState0 &&
              scope.row.state == 0 &&
              isAuth('sample:sampleorder:sampleHandle')
            "
            type="text"
            size="small"
            @click="SampleHandle(scope.row)"
            >打样分配</el-button
          >
          <el-button
            v-if="
              btnState0 &&
              (scope.row.state == 6 || scope.row.state == '6,7') &&
              isAuth('sample:sampleorder:sampleHandle')
            "
            type="text"
            size="small"
            @click="ReallocateSampleHandle(scope.row)"
            >重新分配</el-button
          >
          <el-button
            v-if="
              btnState0 &&
              (scope.row.state == 6 || scope.row.state == '6,7')&&
              isAuth('sample:sampleorder:sampleHandle')
            "
            type="text"
            size="small"
            @click="referenceRecipeHandle(scope.row)"
            >参考配方</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <detail
      v-if="detailVisible"
      ref="detail"
      @refreshDataList="getDataList"
    ></detail>
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
    <orderInfo
      v-if="orderInfoVisible"
      ref="orderInfo"
      @refreshDataList="getDataList"
    ></orderInfo>
    <sample
      v-if="sampleVisible"
      ref="sample"
      @refreshDataList="getDataList"
    ></sample>
    <sampleList
      v-if="sampleListVisible"
      ref="sampleList"
      @refreshDataList="getDataList"
    ></sampleList>
    <productupdatejd
      v-if="productupdateJDVisible"
      ref="productupdatejd"
      @refreshDataList="getDataList"
    ></productupdatejd>
    <billOfParcels
      v-if="billOfParcelsVisible"
      ref="billOfParcels"
      @refreshDataList="getDataList"
    ></billOfParcels>
    <reallocateSample
      v-if="reallocateSampleVisible"
      ref="reallocateSample"
      @refreshDataList="getDataList"
    ></reallocateSample>
  
    <referenceRecipe
    v-if="referenceRecipeVisible"
      ref="referenceRecipe"
      @refreshDataList="getDataList"
    >
    </referenceRecipe>
    <!--新的参考配方，复制老的，参考老的改-->
    <referenceRecipeNew
    v-if="referenceRecipeVisible"
      ref="referenceRecipeNew"
      @refreshDataList="getDataList"
    >
    </referenceRecipeNew>

    <upload
      v-if="uploadVisible"
      ref="upload"
      @refreshDataList="getDataList"
    ></upload>
  </div>
</template>

<script>
import { list, del, checkColor } from '@/api/modules/sampleorder'
import AddOrUpdate from './add-or-update'
import detail from './detail'
import sample from './sample'
import sampleList from './sampleList'
import orderInfo from './orderInfo'
import productupdatejd from './productupdatejd'
import billOfParcels from './billOfParcels'
import { getToken } from '@/utils/auth'
import reallocateSample from './reallocateSample'
import referenceRecipe from './referenceRecipe'
import referenceRecipeNew from './components/referenceRecipeNew'
import Upload from './oss-upload'
import { getQueryString } from '@/utils/index'
import ReferenceRecipe from './referenceRecipe.vue'
var SEARCH_HISTORY_KEY = 'Yitu_Weigh_SAMPLE_SEARCH'
export default {
  data() {
    return {
      state: '',
      dataForm: {
        orderNo: '',
        state: '',
        company: '',
        factoryId: '',
        contactsName: '',
        contactsPhone: '',
        deliverDate: [],
        createDates: [],
        updateDates: []
      },
      factoryList: [],
      importSampleorderval: false,
      States: [],
      sampleTypes: [
        { sampleTypeKey: '0', sampleTypeValue: '新产品打样' },
        { sampleTypeKey: '1', sampleTypeValue: '样粉样板申请' },
        { sampleTypeKey: '2', sampleTypeValue: '配方调整' }
      ],
      vo: {},
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      orderInfoVisible: false,
      uploadVisible: false,
      detailVisible: false,
      sampleVisible: false,
      sampleListVisible: false,
      productupdateJDVisible: false,
      billOfParcelsVisible: false,
      reallocateSampleVisible: false,
      referenceRecipeVisible: false,
      factoryState: false,
      createDate: 'DESC',
      contactsLevel: '',
      deliverDate: '',
      selectData: [],
      btnOpt: false,
      btnState0: false,
      btnState1: false,
      btnState2: false,
      btnState3: false,
      btnState4: false
    }
  },
  components: {
    productupdatejd,
    orderInfo,
    AddOrUpdate,
    detail,
    sample,
    Upload,
    sampleList,
    billOfParcels,
    reallocateSample,
    referenceRecipe,
    referenceRecipeNew
},
  watch: {
    $route: {
      handler() {
        this.getState()
      },
      deep: true
    },
    dataForm: {
      handler() {
        sessionStorage.setItem(
          SEARCH_HISTORY_KEY + this.state,
          JSON.stringify(this.dataForm)
        )
      },
      deep: true
    }
  },
  created() {
    this.getState()
  },
  filters: {
    formatDate(val) {
      if (!val) return '- -'
      var date = new Date(val.replace(/-/g, '/'))
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      month = month < 10 ? '0' + month : month
      var day = date.getDate()
      day = day < 10 ? '0' + day : day
      return year + '-' + month + '-' + day
    }
  },

  methods: {
    isCheckboxDisabled(row) {
      // 在这里定义判断条件，如果满足条件则返回 true，表示禁用复选框
      return !this.btnState4 || row.sampleType !== '1';
    },
    getState() {
      (this.btnOpt = false),
        (this.btnState0 = false),
        (this.btnState1 = false),
        (this.btnState2 = false),
        (this.btnState3 = false),
        (this.btnState4 = false)

      let state = getQueryString(location.href, 'state')
      this.state = state
      const searchForm = sessionStorage.getItem(SEARCH_HISTORY_KEY + state)
      if (searchForm) {
        this.dataForm = JSON.parse(searchForm)
      } else {
        this.dataForm = {
          orderNo: '',
          state: '',
          company: '',
          factoryId: '',
          contactsName: '',
          contactsPhone: '',
          deliverDate: [],
          createDates: [],
          updateDates: []
        }
      }
      if (state) {
        this.btnOpt = false
        this.States = []

        if (state == 0) {
          // 分配订单
          this.btnState0 = true
          this.dataForm.state = '0,6'
        } else if (state == 1) {
          // 检验订单
          this.btnState1 = true
          this.dataForm.state = '7'
        } else if (state == 2) {
          // 验证订单
          this.btnState2 = true
          this.dataForm.state = '2'
        } else if (state == 3) {
          // 关闭订单
          this.btnState3 = true
          this.dataForm.state = '5'
        } else if (state == 4) {
          // 已完成订单
          this.btnState4 = true
          this.dataForm.state = '3'
        }
      } else {
        this.dataForm.state = ''
        this.btnOpt = true
        this.States = [
          { stateKey: '0', stateValue: '未分配' },
          { stateKey: '6', stateValue: '待打样' },
          { stateKey: '1', stateValue: '打样中' },
          { stateKey: '7', stateValue: '待自检' },
          { stateKey: '2', stateValue: '待验证' },
          { stateKey: '5', stateValue: '待结单' },
          { stateKey: '3', stateValue: '已完成' },
          { stateKey: '4', stateValue: '验证驳回' }
        ]
      }
      this.getFactoryList()
      this.getDataList()
    },
    sortChange(column, prop, order) {
      if (column.prop === 'contactsLevel') {
        if (column.order === null) {
          this.clear()
        }
        if (column.order === 'descending') {
          (this.createDate = ''),
            (this.contactsLevel = 'DESC'),
            (this.deliverDate = '')
        }
        if (column.order === 'ascending') {
          (this.createDate = ''),
            (this.contactsLevel = 'ASC'),
            (this.deliverDate = '')
        }
      }
      if (column.prop === 'deliverDate') {
        if (column.order === null) {
          this.clear()
        }
        if (column.order === 'descending') {
          (this.createDate = ''),
            (this.contactsLevel = ''),
            (this.deliverDate = 'DESC')
        }
        if (column.order === 'ascending') {
          (this.createDate = ''),
            (this.contactsLevel = ''),
            (this.deliverDate = 'ASC')
        }
      }
      if (column.prop === 'createDate') {
        if (column.order === null) {
          this.clear()
        }
        if (column.order === 'descending') {
          (this.createDate = 'DESC'),
            (this.contactsLevel = ''),
            (this.deliverDate = '')
        }
        if (column.order === 'ascending') {
          (this.createDate = 'ASC'),
            (this.contactsLevel = ''),
            (this.deliverDate = '')
        }
      }
      this.restPage()
    },
    clear() {
      (this.createDate = ''),
        (this.contactsLevel = ''),
        (this.deliverDate = '')
    },
    //重新分配
    ReallocateSampleHandle(row) {
      this.reallocateSampleVisible = true
      this.$nextTick(() => {
        this.$refs.reallocateSample.init(
          row.id,
          row.sampleOrderNo,
          row.factoryId
        )
      })
    },

    //参考配方
    referenceRecipeHandle(row) {
      this.referenceRecipeVisible = true
      this.$nextTick(() => {
        this.$refs.referenceRecipeNew.init(
          row.id,
          row.existRecipe
        )
      })
    },

    //查色按钮
    queryColor(row) {
      let params = {
        id: row.id
      }
      checkColor(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.$router.push({
            path: '/productLogin',
            query: {
              surface: row.surface,
              brandId: row.brandId,
              factoryId: row.factoryId,
              productCartegry: row.productCartegry,
              gloss: row.gloss,
              glossValue: row.glossValue,
              lAve: row.lAve,
              aAve: row.aAve,
              bAve: row.bAve,
              sampleType: row.sampleType,
              userId: row.userId,
              sampleOrderNo: row.sampleOrderNo,
              id: row.id
            }
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset() {
      sessionStorage.removeItem(SEARCH_HISTORY_KEY + this.state)
      this.dataForm.sampleOrderNo = ''
      this.dataForm.orderNo = ''
      this.dataForm.sampleType = ''
      this.dataForm.paymentType = ''
      this.dataForm.createDates = []
      this.dataForm.updateDates = []
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      var arrOrderTime = this.dataForm.updateDates
      var ustartDate = null
      var uendDate = null
      if (arrOrderTime && arrOrderTime.length > 0) {
        ustartDate = arrOrderTime[0]
        uendDate = arrOrderTime[1]
      }
      var startDate = null
      var endDate = null
      if (this.dataForm.createDates && this.dataForm.createDates.length > 0) {
        startDate = this.dataForm.createDates[0]
        endDate = this.dataForm.createDates[1]
      }
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        sampleOrderNo: this.dataForm.orderNo,
        state: this.dataForm.state,
        factoryId:
          '-1' != this.dataForm.factoryId ? this.dataForm.factoryId : '',
        sampleType: this.dataForm.sampleType,
        ustartDate: ustartDate,
        uendDate: uendDate,
        startDate: startDate,
        endDate: endDate,
        createDate: this.createDate,
        contactsLevel: this.contactsLevel,
        deliverDate: this.deliverDate
      }
      list(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    getFactoryList() {
      this.factoryList = this.$store.getters.factoryList
    },
    //导出excel表格模板
    downImport() {
      var url = `/api/sample/sampleorder/exportSampleOrderExcel?token=${getToken()}`
      // 模拟创建元素实现
      var alink = document.createElement('a')
      alink.href = url
      alink.style = 'display:none'
      alink.click()
    },
    // 上传文件
    importOrder() {
      this.uploadVisible = true
      this.$nextTick(() => {
        this.$refs.upload.init()
      })
    },
    //导出配方excel
    importSampleorder() {
      var arrOrderTime = this.dataForm.updateDates
      var ustartDate = ''
      var uendDate = ''
      if (arrOrderTime && arrOrderTime.length > 0) {
        ustartDate = arrOrderTime[0]
        uendDate = arrOrderTime[1]
      }
      var startDate = ''
      var endDate = ''
      if (this.dataForm.createDates && this.dataForm.createDates.length > 0) {
        startDate = this.dataForm.createDates[0]
        endDate = this.dataForm.createDates[1]
      }
      var sampleType = ''
      if (this.dataForm.sampleType && this.dataForm.sampleType != undefined) {
        sampleType = this.dataForm.sampleType
      }
      var orderNo = ''
      if (this.dataForm.orderNo) {
        orderNo = this.dataForm.orderNo
      }
      var factoryId = ''
      if (this.dataForm.factoryId && '-1' != this.dataForm.factoryId) {
        factoryId = this.dataForm.factoryId
      }
      var sampleOrderId =''
      if (this.selectData.length) {

        sampleOrderId = this.selectData.join(',')
      }
      const params = {
        startDate: this.dataForm.createDates[0],
        endDate: this.dataForm.createDates[1],
        orderNo: this.selectData,
        factoryId: factoryId,
        ustartDate: ustartDate,
        uendDate: uendDate
      }
      // exportData(params).then(res => {

        
      // })
      if (null != this.dataList && this.dataList.length != 0) {
        var url =
          `/api/sample/sampleorder/exportSampleRecipes?token=${getToken()}` +
          `&sampleOrderNo=` +
          orderNo +
          `&sampleType=` +
          sampleType +
          `&factoryId=` +
          factoryId +
          `&startDate=` +
          startDate +
          `&endDate=` +
          endDate +
          `&ustartDate=` +
          ustartDate +
          `&uendDate=` +
          uendDate +
          `&sampleOrderId=` +
          sampleOrderId
        // 模拟创建元素实现
        var alink = document.createElement('a')
        alink.href = url
        alink.style = 'display:none'
        alink.click()
      } else {
        this.$message.error('没有配方数据导出')
      }
    },

    format() {
      var time = new Date()
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      var h = time.getHours()
      var mm = time.getMinutes()
      var s = time.getSeconds()
      // return y+this.add0(m)+this.add0(d)+this.add0(h)+this.add0(mm)+this.add0(s)
      return (
        y.toString() +
        this.add0(m).toString() +
        this.add0(d).toString() +
        this.add0(h).toString() +
        this.add0(mm).toString() +
        this.add0(s).toString()
      )
    },
    add0(m) {
      return m < 10 ? '0' + m : m
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      console.log('全选', val);
      this.dataListSelections = val
      // 多选导出参数传递
      let arr = []
        val.forEach(item => {
          arr.push(item.id)
        })
        this.selectData = arr
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 详情
    addOrUpdateHandleInfo(id) {
      this.orderInfoVisible = true
      this.$nextTick(() => {
        this.$refs.orderInfo.init(id)
      })
    },
    detail(id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    //打样
    SampleHandle(row) {
      this.sampleVisible = true
      this.$nextTick(() => {
        this.$refs.sample.init(row.id, row.sampleOrderNo, row.factoryId)
      })
    },
    //发货单
    billOfParcelsTo(sampleOrderNo) {
      this.billOfParcelsVisible = true
      this.$nextTick(() => {
        this.$refs.billOfParcels.init(sampleOrderNo)
      })
    },
    //结单弹框
    addOrUpdateHandleTo(sampleOrderId, sampleOrderNo) {
      this.productupdateJDVisible = true
      this.$nextTick(() => {
        this.$refs.productupdatejd.init(sampleOrderId, sampleOrderNo)
      })
    },
    //打样单
    SampleListHandle(id, category) {
      this.sampleListVisible = true
      this.$nextTick(() => {
        this.$refs.sampleList.init(id, category)
      })
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id
          })
      this.$confirm(`确定对此条记录进行删除操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.select {
  color: #7679ff !important;
}
</style>
