<template>
    <el-dialog title="编辑参考配方" top="2vh" :append-to-body="true" :close-on-click-modal="false" class="referenceRecipe"
        @close="clearInfo" :visible.sync="visible" width="1165px">
        <div class="stepTitle">
            <el-steps :active="active" finish-status="success" simple style="margin-bottom: 20px;width: 700px;">
                <el-step title="参考配方"></el-step>
                <el-step title="检验计划"></el-step>
            </el-steps>
            <div class="step1" @click="handleStepClick(1)"></div>
            <div class="step2" @click="handleStepClick(2)"></div>
            <el-button v-if="active == 2 && !isDisabled" class="step_btn" type="primary" @click="submitAction()"
                :disabled="dataLoading" style="margin-left: 20px;">完成</el-button>
        </div>
        <div class="smaple-order-info weight" v-if="active == 1">{{ referenceRecipeName }}</div>
        <div class="flex_between" v-if="active == 1">
            <div class="select-recipe">
                <el-select v-model="recipeFlag" :disabled="isDisabled" @change="changeRecipeFlag" class="custom-select"
                    style="margin-right: 10px;">
                    <el-option label="自定义编辑" value="1"></el-option>
                    <el-option label="标准配方库" value="2"></el-option>
                    <el-option label="成品配方库" value="3"></el-option>
                </el-select>
                <el-select :style="{ width: '100px' }" :disabled="isDisabled" v-model="versionNumber"
                    v-if="['2'].includes(recipeFlag)" @focus="fetchVersions" placeholder="Select Version">
                    <el-option v-for="version in versions" :key="version.value" :label="version.label"
                        :value="version.value"></el-option>
                </el-select>
                <el-select v-model="searchValue" v-if="['2', '3'].includes(recipeFlag)" @change="valueChange" filterable
                    remote reserve-keyword placeholder="系列/产品/订单/名称" :remote-method="remoteMethod" :disabled="isDisabled"
                    :loading="loading" class="custom-select">
                    <el-option v-for="(item, index) in options" :key="index" :label="item.option" :value="item.option">
                    </el-option>
                </el-select>
            </div>
            <div class="weight">{{ orderSynopsis }}</div>
        </div>
        <div v-if="active == 1">
            <div class="recipe-info">
                <div>
                    <div class="property-row weight">
                        <span class="property">来源：{{ source }}</span>
                        <span class="property">光泽：{{ gloss }}</span>
                    </div>
                    <div class="property-row weight">
                        <span class="property">分类：
                            <el-select v-model="componentValue" size="mini" :disabled="isDisabled || recipeFlag != '1'"
                                @change="componentValueChange()">
                                <el-option label="单组份" value="1"></el-option>
                                <el-option label="双组份" value="2"></el-option>
                                <el-option label="成品底粉" v-if="isMetalPowder === '0'" value="3"></el-option>
                            </el-select>
                        </span>
                        <span class="property">行业：{{ industry || '-' }}</span>
                    </div>
                </div>
                <div class="material-data">
                    <materialInfo v-if="recipeA && recipeA.mater" :recipe="recipeA" :isDisabled="isDisabled"
                        :isMetalPowder="isMetalPowder" :componentValue="componentValue" :recipeFlag="recipeFlag"
                        sortNum="1"></materialInfo>
                    <materialInfo v-if="recipeB && recipeB.mater" :recipe="recipeB" :isDisabled="isDisabled"
                        :isMetalPowder="isMetalPowder" :componentValue="componentValue" :recipeFlag="recipeFlag"
                        sortNum="2"></materialInfo>
                    <materialInfo v-if="recipeC && recipeC.mater" :recipe="recipeC" :isDisabled="isDisabled"
                        :isMetalPowder="isMetalPowder" :componentValue="componentValue" :recipeFlag="recipeFlag"
                        sortNum="3"></materialInfo>
                </div>
            </div>
        </div>
        <div v-if="active == 2" style="height: 650px;overflow-y: scroll;">
            <div class="inspection-plan" v-show="inspectionPlanData && inspectionPlanData.length">
                <p class="title">检验计划</p>
                <div class="plan_content">
                    <!-- <div v-for="(item, index) in inspectionPlanData" :key="index" class="plan_wrap">
                        <span v-if="!item.isEdit">{{ item.name }}</span>
                        <el-input v-else v-model="item.name" size="mini" disabled @blur="item.isEdit = false"></el-input>
                        <span v-if="!item.isEdit" @click="isDisabled ? '' : planEdit(item)">{{ item.data }} </span>
                        <el-input v-else class="plan_row" v-model="item.data" size="mini" @blur="item.isEdit = false"></el-input>
                    </div> -->
                    <div v-for="(item, index) in inspectionPlanData" :key="index" class="plan_wrap">
                        <span v-if="!item.isEdit">{{ item.name }}</span>
                        <el-input v-else v-model="item.name" size="mini" disabled @blur="item.isEdit = false"></el-input>
                        <span v-if="!item.isEdit || isDisabled">{{ item.data }} </span>
                        <el-input v-else class="plan_row" v-model="item.data" size="mini"></el-input>
                    </div>
                </div>
            </div>
            <div class="recipe-explain" v-if="(recipeFlag !== '1' && (recipeA || recipeB || recipeC)) || isDisabled">
                <div class="explain">说明:</div>
                <el-input type="textarea" v-model="explainData" :rows="10" :disabled="true"></el-input>
            </div>
        </div>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="clearInfo()" size="small">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">确定</el-button>
        </span> -->
    </el-dialog>
</template>
  
<script>
import {
    queryRecipeData,
    querySampleOrderInfo
} from "@/api/modules/sampleorder";
import { saveReferenceRecipe, queryRecipeInfo, selectStandardRecipeVersionNumber } from "@/api/modules/recipe"
import { multiply } from '@/utils/validate';
import materialInfo from './materialInfo'
const arr = [{ name: ' 固化条件', data: '', isEdit: true }, { name: '膜厚', data: '', isEdit: true }, { name: '杯突', data: '', isEdit: true }, { name: '弯曲', data: '', isEdit: true }, { name: '20inch/bs冲击', data: '', isEdit: true },
{ name: '划格', data: '', isEdit: true }, { name: '光泽', data: '', isEdit: true }, { name: '胶化时间', data: '', isEdit: true }, { name: '斜板流动', data: '', isEdit: true }, { name: '12um粒度', data: '', isEdit: true },
{ name: '32um粒度', data: '', isEdit: true }, { name: '63um粒度', data: '', isEdit: true }, { name: '流动', data: '', isEdit: true }, { name: '粉温', data: '', isEdit: true }]
export default {
    data() {
        return {
            active: 1,
            visible: false,
            dataLoading: false,
            options: [],
            searchValue: '',
            list: [],
            source: '',
            gloss: '',
            orderSynopsis: '',
            industry: '-',
            loading: false,
            recipeFlag: '1', // 头部的类型默认自定义编辑
            isDisabled: false, // 状态不可编辑作用域全局
            referenceRecipeName: '',
            sampleOrderId: '',
            isMetalPowder: "", //1.普通粉 2.金属粉
            componentValue: '',
            recipeA: null,
            recipeB: null,
            recipeC: null,
            newMaterial: {
                materialNo: "",
                materialValue: ""
            },
            componentB: "A",
            totalWeightB: 0,
            componentC: "B",
            totalWeightC: 0,
            inspectionPlanData: [],//检验计划
            explainData: '',//说明
            versionNumber: '',
            versions: [],
        };
    },
    components: {
        materialInfo
    },
    created() {
        this.getzYear(),
            this.componentValueChange()
    },
    methods: {
        init(id, existRecipe) {
            this.sampleOrderId = id
            this.visible = true;
            this.active = 1;
            var param = {
                orderId: id
            }
            querySampleOrderInfo(param).then((res) => {
                if (res.data.code === 0) {
                    this.source = res.data.querySampleOrderInfo.sampleOrderNo
                    this.gloss = res.data.querySampleOrderInfo.gloss
                    this.orderSynopsis = res.data.querySampleOrderInfo.orderSynopsis
                    //行业
                    this.industry = res.data.querySampleOrderInfo.industry
                    //是金属粉0，还是普通粉1
                    this.isMetalPowder = res.data.querySampleOrderInfo.productCartegry
                    this.referenceRecipeName = res.data.querySampleOrderInfo.referenceRecipeName
                }
            })
            //如果existRecipe 说明已经分配参考配方，直接反显，并可不能修改
            if (existRecipe === '0') {
                this.isDisabled = true;
                queryRecipeInfo(param).then((res) => {
                    if (res.data.code === 0) {
                        //formulaSourceType:1自定义，2标注配方库3成品配方库
                        this.recipeFlag = res.data.recipeVo.formulaSourceType;
                        //formulaClassify 1单组份，2双组份, 3底粉
                        this.componentValue = res.data.recipeVo.formulaClassify;
                        //备注
                        this.explainData = res.data.recipeVo.remarks;
                        //recipeA表示主配方单
                        if (res.data.recipeVo.materialsVosMap && res.data.recipeVo.materialsVosMap.A) {
                            this.recipeA = { mater: res.data.recipeVo.materialsVosMap.A };
                        }
                        if (res.data.recipeVo.materialsVosMap && res.data.recipeVo.materialsVosMap.B) {
                            this.recipeB = { mater: res.data.recipeVo.materialsVosMap.B };
                        }
                        if (res.data.recipeVo.materialsVosMap && res.data.recipeVo.materialsVosMap.C) {
                            this.recipeC = { mater: res.data.recipeVo.materialsVosMap.C };

                        }
                        this.inspectionPlanData = res.data.recipeVo.planList.map((item) => {
                            const keys = Object.keys(item);
                            return {
                                name: keys[0],
                                data: item[keys[0]],
                                isEdit: true,
                            }
                        });
                    }
                })
            } else {
                this.changeRecipeFlag('1')
            }
        },
        handleStepClick(e) {
            if (this.active === 1 && e === 2 && !this.isDisabled) {
                this.dataFormSubmit(true);
            } else {
                this.active = e;
            }
        },
        changeRecipeFlag(e) {
            this.recipeFlag = e
            this.componentValue = ''
            this.searchValue = ''
            this.recipeA = null
            this.recipeB = null
            this.recipeC = null
            this.source = ''
            this.explainData = ''
            if (['2', '3'].includes(e)) {
                this.inspectionPlanData = []
                // 根据系列和产品号去查
            } else {
                this.inspectionPlanData = arr
            }
        },

        fetchVersions() {
            selectStandardRecipeVersionNumber().then((res) => {
                if (res.data.code === 0) {
                    this.versions = res.data.list
                    console.log(this.versions)
                }
            })
        },
        getzYear() {
            this.versionNumber = new Date().getFullYear();
        },
        remoteMethod(query) {
            var params = {
                recipeFlag: this.recipeFlag,
                searchValue: query,
                versionNumber: this.versionNumber
            }
            if ((query.length >= 3 && (this.recipeFlag === '2') || (query.length >= 7 && this.recipeFlag === '3'))) {
                queryRecipeData({ params }).then(
                    ({ data }) => {
                        if (data && data.code === 0) {
                            //下拉选项
                            this.options = data.recipeVoList
                        }
                    }
                );
            }
        },
        valueChange(row) {
            this.options.map((e) => {
                if (e.option === row) {
                    this.componentValue = e.size;
                    this.explainData = e.remarks;
                    if (e.materialsVosMap.A) {
                        this.recipeB = { mater: e.materialsVosMap.A };
                    } else {
                        this.recipeB = null
                    }
                    if (e.materialsVosMap.B) {
                        this.recipeC = { mater: e.materialsVosMap.B };
                    } else {
                        this.recipeC = null
                    }
                    // 配方库里面没有金属粉，c用不到即a用不到
                    if (e.materialsVosMap.C) {
                        this.recipeA = { mater: e.materialsVosMap.C };
                    } else {
                        this.recipeA = null
                    }
                    // 
                    if (this.isMetalPowder === '0' && !this.recipeA) {
                        this.recipeA = { mater: [{ isEdit: true, materialNo: '', materialValue: '' }] }
                    }
                    this.inspectionPlanData = e.planList.map((item) => {
                        const keys = Object.keys(item);
                        return {
                            name: keys[0],
                            data: item[keys[0]],
                            isEdit: true,
                        }
                    });
                    this.source = e.option
                }
            })
        },
        //普通粉单组份，普通粉双组份，金属粉双组份
        componentValueChange() {
            setTimeout(() => {
                if (this.componentValue === '1') {// 单组分
                    if (this.isMetalPowder === '0') { // 金属粉
                        this.recipeA = { mater: [{ isEdit: true, materialNo: '', materialValue: '' }] }
                        this.recipeB = { mater: [{ isEdit: true, materialNo: '', materialValue: '' }] }
                        this.recipeC = null
                    } else {
                        this.recipeA = null
                        this.recipeB = { mater: [{ isEdit: true, materialNo: '', materialValue: '' }] }
                        this.recipeC = null
                    }
                } else if (this.componentValue === '2') { // 双组分
                    if (this.isMetalPowder === '0') { // 金属粉
                        this.recipeA = { mater: [{ isEdit: true, materialNo: '', materialValue: '' }] }
                        this.recipeB = { mater: [{ isEdit: true, materialNo: '', materialValue: '' }] }
                        this.recipeC = { mater: [{ isEdit: true, materialNo: '', materialValue: '' }] }
                    } else {
                        this.recipeA = null
                        this.recipeB = { mater: [{ isEdit: true, materialNo: '', materialValue: '' }] }
                        this.recipeC = { mater: [{ isEdit: true, materialNo: '', materialValue: '' }] }
                    }
                } else if (this.componentValue === '3') { // 只有金属粉的时候才存在
                    this.recipeA = { mater: [{ isEdit: true, materialNo: '', materialValue: '' }] }
                    this.recipeB = null
                    this.recipeC = null
                } else {
                    this.recipeA = null
                    this.recipeB = null
                    this.recipeC = null
                }
            }, 10);

        },
        handleData_ordinary(recipe, type) {
            var total = 0
            var show = true
            var Tshow = true
            recipe.mater && recipe.mater.map((e) => {
                if (!e.materialNo) {
                    show = false
                    var obj = e
                    e.error = '1'
                } else if (isNaN(Number(e.materialValue)) || Number(e.materialValue) < 0.01) {
                    show = false
                    e.error = '3'
                } else {
                    delete e.error;
                }
                total = multiply(total, Number(e.materialValue));
            })
            if (!type && total != 1000) {
                Tshow = false
            }
            return { recipe, Tshow, show }
        },
        handleData_metal(recipe, type) {
            var total = 0
            var show = true
            var Tshow = true
            recipe.mater && recipe.mater.map((e) => {
                if (!e.isProduct) {
                    if (e.materialNo || e.materialValue) {
                        if (!e.materialNo) {
                            show = false
                            var obj = e
                            e.error = '1'
                        } else if (isNaN(Number(e.materialValue)) || Number(e.materialValue) < 0.01) {
                            show = false
                            e.error = '3'
                        } else {
                            delete e.error;
                        }
                    } else {
                        delete e.error;
                    }
                }
                total = multiply(total, Number(e.materialValue));
            })
            if (!type && total != 1000) {
                Tshow = false
            }
            return { recipe, Tshow, show }
        },
        handleData(recipe, errArr) {
            recipe.mater && recipe.mater.map((e) => {
                if (errArr.includes(e.materialNo)) {
                    e.error = '2'
                }
            })
            return recipe
        },
        handleData_recipe(recipe, errArr) {
            recipe.mater && recipe.mater.map((e) => {
                if (errArr.includes(e.materialNo)) {
                    e.error = '4'
                }
            })
            return recipe
        },
        handleSubmitData(mater) {
            var show = true
            var arr = [];
            mater.map((e) => {
                e.materialNo = e.materialNo.trim();
                e.materialValue = e.materialValue.trim();
                if (e.materialNo || e.materialValue) {
                    arr.push(e);
                    show = false
                }
            })
            if (show) {
                return null
            } else {
                return arr
            }
        },
        dataFormSubmit(check) {
            if (this.isDisabled) {
                this.clearInfo()
                return;
            }
            var show = true
            var Tshow = true
            // 校验
            // error 1 物料号为null 2 物料号不在库 3 物料重量不合法
            if (this.isMetalPowder === '0') { // 金属粉校验
                if (this.recipeA && this.recipeA.mater) {
                    var objA_ = this.handleData_metal(this.recipeA, 'A');
                    if (Tshow) Tshow = objA_.Tshow
                    if (show) show = objA_.show
                    this.recipeA = JSON.parse(JSON.stringify(objA_.recipe))
                }
                if (this.recipeB && this.recipeB.mater) {
                    var objB_ = this.handleData_metal(this.recipeB);
                    if (Tshow) Tshow = objB_.Tshow
                    if (show) show = objB_.show
                    this.recipeB = JSON.parse(JSON.stringify(objB_.recipe))
                }
                if (this.recipeC && this.recipeC.mater) {
                    var objC_ = this.handleData_metal(this.recipeC);
                    if (Tshow) Tshow = objC_.Tshow
                    if (show) show = objC_.show
                    this.recipeC = JSON.parse(JSON.stringify(objC_.recipe))
                }
            } else {
                if (this.recipeA && this.recipeA.mater) {
                    var objA = this.handleData_ordinary(this.recipeA, 'A');
                    if (Tshow) Tshow = objA.Tshow
                    if (show) show = objA.show
                    this.recipeA = JSON.parse(JSON.stringify(objA.recipe))
                }
                if (this.recipeB && this.recipeB.mater) {
                    var objB = this.handleData_ordinary(this.recipeB);
                    if (Tshow) Tshow = objB.Tshow
                    if (show) show = objB.show
                    this.recipeB = JSON.parse(JSON.stringify(objB.recipe))
                }
                if (this.recipeC && this.recipeC.mater) {
                    var objC = this.handleData_ordinary(this.recipeC);
                    if (Tshow) Tshow = objC.Tshow
                    if (show) show = objC.show
                    this.recipeC = JSON.parse(JSON.stringify(objC.recipe))
                }
            }
            console.log(this.recipeA, this.recipeB, this.recipeC, show, Tshow);
            if (show && !Tshow) {
                // this.$message.error("总重量需为1000");
                this.$confirm('存在非1000g的配方, 确认提交?', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (check) {
                        this.active = 2
                    }
                })
            }
            if (show && Tshow) {
                if (check) {
                    this.active = 2
                }
            }
        },
        submitAction() {
            this.dataLoading = true
            const formData = {
                materialsVosMap: {
                    "A": this.recipeA && this.recipeA.mater ? this.handleSubmitData(this.recipeA.mater) : null,
                    "B": this.recipeB && this.recipeB.mater ? this.handleSubmitData(this.recipeB.mater) : null,
                    "C": this.recipeC && this.recipeC.mater ? this.handleSubmitData(this.recipeC.mater) : null,
                },
                planList: this.inspectionPlanData,
                sampleOrderId: this.sampleOrderId,
                productCartegry: this.isMetalPowder,
                formulaClassify: this.componentValue,
                formulaSourceType: this.recipeFlag
            }
            // queryRecipeData(formData).then((res) => {
            saveReferenceRecipe(formData).then((res) => {
                //TODO 校验无聊数据全部填写了建议称重
                if (res && res.data.code === 0) {
                    if (res.data.failMaterialData && res.data.failMaterialData.length) {
                        this.$message.error("存在未入库的物料号");
                        if (this.recipeA && this.recipeA.mater) {
                            this.recipeA = JSON.parse(JSON.stringify(this.handleData(this.recipeA, res.data.failMaterialData)))
                        }
                        if (this.recipeB && this.recipeB.mater) {
                            this.recipeB = JSON.parse(JSON.stringify(this.handleData(this.recipeB, res.data.failMaterialData)))
                        }
                        if (this.recipeC && this.recipeC.mater) {
                            this.recipeC = JSON.parse(JSON.stringify(this.handleData(this.recipeC, res.data.failMaterialData)))
                        }
                        this.dataLoading = false;
                    } else if (res.data.repeatedItems) {
                        this.$message.error("存在重复的物料号");
                        if (this.recipeB && this.recipeB.mater && res.data.repeatedItems.B) {
                            this.recipeB = JSON.parse(JSON.stringify(this.handleData_recipe(this.recipeB, res.data.repeatedItems.B)))
                        }
                        if (this.recipeC && this.recipeC.mater && res.data.repeatedItems.C) {
                            this.recipeC = JSON.parse(JSON.stringify(this.handleData_recipe(this.recipeC, res.data.repeatedItems.C)))
                        }
                        this.dataLoading = false;
                    } else {
                        this.$message({
                            message: "下发参考配方成功",
                            type: "success",
                            duration: 1500,
                            onClose: () => {
                                this.visible = false;
                                this.dataLoading = false;
                                this.$emit("refreshDataList");
                            },
                        });
                    }
                } else {
                    this.$message.error(res.data.msg);
                    this.dataLoading = false;
                }
            })
        },
        planEdit(item) {
            item.isEdit = true
        },
        confirmAdd() {
            // 将新物料数据添加到配方中
            this.recipe.mater.push({
                materialCode: this.newMaterial.materialCode,
                materialWeight: this.newMaterial.materialWeight
            });
            // 更新总重量
            this.calculateTotalWeight();
            // 清空输入框
            this.newMaterial.materialCode = "";
            this.newMaterial.materialWeight = "";
        },
        calculateTotalWeight() {
            // 计算总重量
            this.totalWeight = this.recipe.mater.reduce(
                (total, item) => total + parseFloat(item.materialWeight),
                0
            );
        },
        clearInfo() {
            this.visible = false;
            this.isDisabled = false;
            this.options = [];
            this.recipeA = null
            this.recipeB = null
            this.recipeC = null
        }

    },




};
</script>
<style lang="scss" scope>
.referenceRecipe .el-dialog__body {
    padding-top: 0px;
}

.referenceRecipe {

    .flex_between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .select-recipe {
        display: flex;
        width: 500px;
        align-items: center;
    }

    .recipe-info {
        height: 578px;
        width: 1130px;
        margin-top: 15px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        padding: 10px;
        overflow-y: scroll;
    }

    .custom-select {
        width: 230px;
    }

    .search-recipe-text {
        width: 200px;
        height: 40px;
        display: flex;
        justify-content: center;
        /* 水平居中 */
        align-items: center;
        /* 垂直居中 */
        text-align: center;
        /* 文本居中对齐 */
        line-height: 80px;
        /* 垂直居中文本 */
        margin: 0 200px;

        /* 设置div之间的间距 */
        .span-text {
            text-align: center;
            /* 文本水平居中 */
            display: inline-block;
            /* 使文本元素可以垂直居中 */
        }
    }

    .smaple-order-info {
        width: 100%;
        text-align: center;
    }

    .plan_wrap {
        display: flex;
    }

    .plan_wrap input {
        height: 38px !important;
        line-height: 38px;

    }

    .plan_wrap .plan_row input {
        border-right: none;
    }

    .property-row {
        width: 100%;
        display: flex;
        align-items: center;
        height: 40px;

        >span {
            display: inline-block;
            flex: 1;
            text-align: left;
        }
    }

    .material-data {
        width: 1080px;
        box-sizing: border-box;
        height: 530px;
        display: flex;
        margin-top: 10px;

        >div {
            margin-right: 15px;

            &:last-child {
                margin-right: 0px;
            }
        }
    }

    .material-info {
        position: relative;
        width: 600px;
        height: 500px;
        margin: 0 10px;

    }

    .inspection-plan {
        width: 600px;
        // height: 500px;
        margin-top: 20px;
        text-align: center;
        border: 1px solid #DCDFE6;

    }

    .recipe-explain {
        width: 100%;
        margin-top: 20px;

    }

    .inspection-plan .title {
        font-size: 16px;
        color: #000;
        border-bottom: 1px solid #DCDFE6;
        margin: 0;
        height: 38px;
        line-height: 38px;
    }

    .inspection-plan div span {
        display: inline-block;
        width: 50%;
        height: 38px;
        line-height: 38px;
        color: #606266;
        border-bottom: 1px solid #DCDFE6;
    }

    .inspection-plan .plan_content div:last-child span {
        border-bottom: none;
    }

    .inspection-plan div span:first-child {
        border-right: 1px solid #DCDFE6;
    }

    .inspection-plan .plan_content {
        height: calc(100% - 40px);
        overflow-y: scroll;
    }

    .plan_content span i {
        float: right;
        margin-top: 12px;
        margin-right: 7px;
    }

    .weight {
        font-weight: bold;
    }

    .stepTitle {
        height: 65px;
        display: flex;
        align-items: center;
        padding: 20px 100px 0px 100px;
        position: relative;

        .step1 {
            width: 350px;
            cursor: pointer;
            opacity: 0.3;
            top: 10px;
            height: 45px;
            position: absolute;
            z-index: 2;
            left: 100px;
        }

        .step2 {
            width: 350px;
            cursor: pointer;
            opacity: 0.3;
            top: 10px;
            height: 45px;
            position: absolute;
            z-index: 2;
            left: 450px;
        }

        .step_btn {
            margin-top: -20px;
        }
    }
}</style>
  