<template>
  <sides-lip ref="SidesLip" @click="close()">
    <div slot="soltForm">
      <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" label-width="120px" v-loading="dataLoading"
        style="width: 100%" element-loading-spinner="el-icon-loading">
        <div style="display: flex">
          <div style="width: 70%">
            <el-form-item label="实验订单号" prop="productOrder">
              <el-input v-model="dataForm.productOrder" placeholder="实验订单号" size="small" style="width: 100%"
                maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="dataForm.productName" placeholder="产品名称" size="small" style="width: 100%"
                maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="测试LAB值">
              <div>
                <read-device @read="dataReceived" @readError="loading = false" />
                <inline-message ref="inlineMessage" style="margin-top: 10px" />
              </div>
            </el-form-item>
            <el-form-item>
              <span slot="label"><label style="color: #fa3d1e">*</label> LAB值</span>
              <el-row>
                <el-col :span="8">
                  <el-form-item prop="lAve">
                    <el-input v-model="dataForm.lAve" placeholder="L值" class="w150 mr10" size="small" clearable
                      maxlength="30" @input="getE00"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="aAve">
                    <el-input v-model="dataForm.aAve" placeholder="A值" class="w150 mr10" size="small" clearable
                      maxlength="30" @input="getE00"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="bAve">
                    <el-input v-model="dataForm.bAve" placeholder="B值" class="w150 mr10" size="small" clearable
                      maxlength="30" @input="getE00"></el-input>
                  </el-form-item>
                </el-col>
                <el-col> </el-col>
              </el-row>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="产品编号" prop="productNo">
              <el-input v-model="dataForm.productNo" placeholder="产品编号" size="small" style="width: 100%"
                maxlength="30"></el-input>
            </el-form-item>
            <el-form-item>
              <el-image v-if="dataForm.picUrl" style="width: 100px; height: 100px" :src="dataForm.picUrl"
                fit="contain"></el-image>
              <div v-else-if="!dataForm.picUrl && dataForm.hex" :style="{
                backgroundColor: dataForm.hex,
                height: '100px',
                width: '100px'
              }"></div>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="">
          <el-collapse @change="handleChange">
            <el-collapse-item>
              <span class="collapse-title" slot="title">查看测量数据</span>
              <div>
                <ul class="productLAB">
                  <li>
                    <span v-for="x in productLAB.title" class="title">{{
                      x
                    }}</span>
                  </li>
                  <li v-for="(x, index) in productLAB.data">
                    <span class="title">{{ x }}</span>
                    <el-input v-model="dataForm[`l${index + 1}`]" placeholder="L值" class="w100"
                      disabled="disabled"></el-input>
                    <el-input v-model="dataForm[`a${index + 1}`]" placeholder="A值" class="w100"
                      disabled="disabled"></el-input>
                    <el-input v-model="dataForm[`b${index + 1}`]" placeholder="B值" class="w100"
                      disabled="disabled"></el-input>
                  </li>
                </ul>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>
        <el-form-item label="色差值" prop="e00">
          <el-input v-model="dataForm.e00" placeholder="色差值" size="small" style="width: 40%"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-collapse @change="handleChange">
            <el-collapse-item>
              <span class="collapse-title" slot="title">查看参考标准</span>
              <div style="display: flex">
                <div style="width: 60%">
                  <p class="ziti">小于0.3</p>
                  <p class="ziti">小于0.5</p>
                  <p class="ziti">大于2.0</p>
                </div>
                <div style="width: 40%">
                  <p class="ziti">小于1.0</p>
                  <p class="ziti">小于2.0</p>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>
        <div style="display: flex">
          <div style="width: 70%">
            <el-form-item label="光泽值" prop="glossValue">
              <el-input v-model="dataForm.glossValue" placeholder="光泽值" size="small" @input="selectGlossInfo()"
                style="width: 100%"></el-input>
            </el-form-item>
          </div>
          <div style="width: 30%">
            <el-form-item label="" prop="gloss" label-width="20px">
              <el-select v-model="dataForm.gloss" size="small" placeholder="" style="width: 100%" disabled="disabled">
                <el-option v-for="item in productGlossList" :key="item.id" :label="item.name" :value="item.id"
                  size="small"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="">
          <el-collapse @change="handleChange">
            <el-collapse-item>
              <span class="collapse-title" slot="title">查看参考标准</span>
              <div style="display: flex">
                <div style="width: 60%">
                  <template v-for="(item, index) in glossList">
                    <p v-if="index % 2 == 0" class="ziti">{{ item.label }}</p>
                  </template>
                </div>
                <div style="width: 60%">
                  <template v-for="(item, index) in glossList">
                    <p v-if="index % 2 == 1" class="ziti">{{ item.label }}</p>
                  </template>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>
        <div class="ces-main">
          <search-form ref="form" size="small" labelWidth="120px" :searchData="searchData" :searchForm="searchForm"
            :notIncluded="notIncluded"></search-form>
        </div>
        <div style="display: flex">
          <div style="width: 50%">
            <el-form-item label="标准色号" prop="ralColorNo">
              <el-input v-model="dataForm.ralColorNo" placeholder="产品标准色号" size="small" style="width: 100%"
                maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="固化温度" prop="temperature">
              <el-input v-model="dataForm.temperature" placeholder="固化温度" size="small" maxlength="5" style="width: 100%">
                <template slot="append">℃</template>
              </el-input>
            </el-form-item>
          </div>
          <div style="width: 50%">
            <el-form-item label="固化时间" prop="solTime">
              <el-input v-model="dataForm.solTime" placeholder="固化时间" size="small" maxlength="5" style="width: 100%">
                <template slot="append">秒</template>
              </el-input>
              <!--</el-date-picker>-->
            </el-form-item>
          </div>
        </div>
        <div style="
            border: solid 1px silver;
            padding: 15px 15px;
            margin-bottom: 15px;
          ">
          <span style="font-size: 18px; font-weight: bold">检验计划</span>
          <el-divider style="width: 100px"></el-divider>
          <div class="inspection-plan" v-show="dataForm.isShowPlan">
            <div class="plan_content">
              <div v-for="(item, index) in dataForm.inspectionPlanData" :key="index" class="plan_wrap">
                <span v-if="item.isEdit">{{ item.name }}</span>
                <el-input v-else v-model="item.name" size="mini" disabled @blur="item.isEdit = false"></el-input>
                <span v-if="item.isEdit || isDisabled">{{ item.data }} </span>
                <el-input v-else class="plan_row" v-model="item.data" size="mini"></el-input>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div slot="soltFooter">
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">返回</el-button>
        <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">确定</el-button>
      </span>
    </div>
    <!--</el-dialog>-->
  </sides-lip>
</template>

<script>
import {
  queryNoSeries,
  queryNoColor,
  selectGloss,
  getIndustryList,
  getDict,
  getColorSeriesList,
  getProductPerformance,
  getProductHierarchy,
  getProductSeries,
  getProductCategory,
  validateSampleProductNo
} from '@/api/modules/product'
import { getProductPerformanceS } from '@/api/modules/sampleorder'
import { selfInspection, getE00 } from '@/api/modules/sampleproduct'
import Tinymce from '@/components/Tinymce'
import { getToken } from '@/utils/auth'
import SidesLip from '@/components/SidesLip'
import SearchForm from '@/components/form.vue'
import { getQueryString } from '@/utils/index'
import {
  getAttrAndValueListInfo,
  getGlossInfo,
  selectGlossInfo
} from '@/api/modules/productattr'
import { sampleproduct } from '@/api/modules/sampleproduct'
import { querySolidify, getSolidifyInFo } from '@/api/modules/recipe'
import { getProductAttrValue } from '@/api/modules/productattrvalue'
import ReadDevice from '../../components/readDevice.vue'
import InlineMessage from '../../components/inlineMessage.vue'
import { getSecondsAgoMessage } from '@/utils/momentUtil'
const arr = [{ name: ' 固化条件', data: '', isEdit: false }, { name: '膜厚', data: '', isEdit: false }, { name: '杯突', data: '', isEdit: false }, { name: '弯曲', data: '', isEdit: false }, { name: '20inch/bs冲击', data: '', isEdit: false },
{ name: '划格', data: '', isEdit: false }, { name: '光泽', data: '', isEdit: false }, { name: '胶化时间', data: '', isEdit: false }, { name: '斜板流动', data: '', isEdit: false }, { name: '12um粒度', data: '', isEdit: false },
{ name: '32um粒度', data: '', isEdit: false }, { name: '63um粒度', data: '', isEdit: false }, { name: '流动', data: '', isEdit: false }, { name: '粉温', data: '', isEdit: false }]
export default {
  components: {
    Tinymce,
    SidesLip,
    SearchForm,
    ReadDevice,
    InlineMessage
  },
  data() {
    var validateProductNoOnly = (rule, value, callback) => {
      //校验产品编号唯一
      if (
        this.dataForm.productNo != '0' &&
        this.dataForm.productNo != '' &&
        this.dataForm.productId != ''
      ) {
        let params = {
          productNo: this.dataForm.productNo,
          sampleOrderNo: this.dataForm.productOrder,
          id: this.dataForm.productId
        }
        validateSampleProductNo(params).then(({ data }) => {
          if (data && data.code === 0) {
            return callback()
          } else {
            return callback(new Error(data.msg))
          }
        })
      } else {
        return callback()
      }
    }
    return {

      notIncluded: [],
      searchForm: [],
      searchData: {},
      productAttrId: '',
      attrValue: '',
      attrLoading: false,
      glossList: [],
      biaoZhun: false,
      showLAB: false,
      loading: false,
      action: `${process.env.VUE_APP_BASE_API
        }/sys/oss/upload?token=${getToken()}`,
      visible: false,
      dataLoading: false,
      productLAB: {
        title: ['角度', 'L值', 'A值', 'B值'],
        data: ['25度', '45度', '75度']
      },
      dataForm: {
        e00: '',
        productOrder: '',
        id: 0,
        productId: '',
        productNo: '',
        productName: '',
        makeFactory: '',
        picUrl: '',
        qrUrl: '',
        hex: '',
        ralColorNo: '',
        gloss: '',
        glossValue: '',
        surface: '',
        colorSeries: '',
        productSeries: '',
        productPerformance: '',
        productPerformanceS: '',
        otherPerformance: '',
        productCategory: '',
        productHierarchy: '',
        industryId: '',
        factoryId: '',
        brandId: '',
        description: '',
        l1: '',
        a1: '',
        b1: '',
        l2: '',
        a2: '',
        b2: '',
        l3: '',
        a3: '',
        b3: '',
        lAve: '',
        aAve: '',
        bAve: '',
        isOpen: '',
        delFlag: '',
        createBy: '',
        createDate: '',
        updateBy: '',
        updateDate: '',
        remarks: '',
        test: '1',
        solidifyId: '',
        solTime: '',
        temperature: '',
        solidifyDesc: '',
        thickness: '',
        bend: '',
        attack: '',
        delimit: '',
        cupular: '',
        other: '',
        inspectionPlanData: arr,//检验计划
        isShowPlan: true,
      },

      performanceList: [],
      industryList: [],
      colorSeriesList: [],
      productCategoryList: [],
      otherPerformanceList: [],
      productSeriesList: [],
      productGlossList: [],
      productSurfaceList: [],
      productHierarchyList: [],
      dataRule: {
        productNo: [{ validator: validateProductNoOnly, trigger: 'blur' }],
        lAve: [{ required: true, message: 'L平均值不能为空', trigger: 'blur' }],
        aAve: [{ required: true, message: 'A平均值不能为空', trigger: 'blur' }],
        bAve: [{ required: true, message: 'B平均值不能为空', trigger: 'blur' }],
        glossValue: [
          { required: true, message: '光泽值不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created() { },
  beforeDestroy() {
    window.clearInterval(this.timer)
    this.timer = null
    this.timerNum = 0
  },
  methods: {
    getProductAttrValue() {
      let params = {}
      getProductAttrValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          for (var i = 0; i < data.list.length; i++) {
            if (data.list[i].productAttrType === '1') {
              //表面
              this.productSurfaceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '3') {
              //系列
              this.productSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '6') {
              //主题
              this.productHierarchyList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '2') {
              //色系
              this.colorSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '7') {
              //性能
              this.performanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '4') {
              //应用
              this.industryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '8') {
              //功能
              this.otherPerformanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '5') {
              //分类
              this.productCategoryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '9') {
              //光泽
              this.productGlossList = data.list[i].productAttrValue
            }
          }
        } else {
        }
      })
    },
    getAttrAndValueListInfo: function () {
      this.notIncluded = []
      this.searchData = {}
      this.searchForm = []
      this.tableCols = []
      var params = {
        type: 'detail',
        isUniversal: '0'
      }
      getAttrAndValueListInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          data.data.forEach((e) => {
            var obj = { type: '', label: '', width: '180px' }
            if (e.type == '1') {
              // 如果是单选
              obj.type = 'Select'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.label = e.value
            }
            if (e.type == '2') {
              obj.type = 'SelectMultiple'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
            }
            if (e.type == '3') {
              obj.type = 'Cascader'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
            }
            obj.label = e.label
            this.$set(this.searchData, e.productAttrId, e.value)
            this.searchForm.push(obj)
          })
        } else {
          this.$message.error(data.msg)
          this.loading2 = false
        }
      })
    },
    getE00() {
      let params = {
        lAve: this.dataForm.lAve,
        aAve: this.dataForm.aAve,
        bAve: this.dataForm.bAve,
        sampleProductId: this.dataForm.id
      }
      getE00(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.e00 = data.e00
        } else {
          this.dataForm.e00 = ''
        }
      })
    },
    solidifyDD(id) {
      var params = {
        id: id
      }
      //固化
      getSolidifyInFo(params).then(({ data }) => {
        if (data.code == 0) {
          this.dataForm.solidifyName = data.solidifyInFo.solidifyName
          this.dataForm.temperature = data.solidifyInFo.temperature
          this.dataForm.solTime = data.solidifyInFo.solTime
          this.dataForm.solidifyDesc = data.solidifyInFo.description
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    handleChange(val) {
      console.log(val)
    },
    //LAB值按钮隐藏显示
    isShowLAB() {
      this.showLAB = !this.showLAB
    },
    isBiaoZhun() {
      this.biaoZhun = !this.biaoZhun
    },
    //根据光泽测试值显示光泽范围名称
    getSelectGloss() {
      let params = {
        glossValue: this.dataForm.glossValue
      }
      selectGloss(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.gloss = data.DictList.dictId
        } else {
          this.dataForm.gloss = ''
        }
      })
    },
    planEdit(item) {
      item.isEdit = true
    },
    //根据光泽测试值显示光泽范围名称
    selectGlossInfo() {
      this.dataForm.glossValue = this.dataForm.glossValue.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
      this.dataForm.glossValue = this.dataForm.glossValue.replace(
        /\.{2,}/g,
        '.'
      ) //只保留第一个. 清除多余的
      this.dataForm.glossValue = this.dataForm.glossValue
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
      this.dataForm.glossValue = this.dataForm.glossValue.replace(
        /^(\-)*(\d+)\.(\d).*$/,
        '$1$2.$3'
      ) //只能输入两个小数
      if (
        this.dataForm.glossValue.indexOf('.') < 0 &&
        this.dataForm.glossValue != ''
      ) {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        this.dataForm.glossValue = parseFloat(this.dataForm.glossValue)
      }
      this.$set(this.searchData, '1-' + this.productAttrId, '')
      let params = {
        glossValue: this.dataForm.glossValue
          ? parseInt(this.dataForm.glossValue)
          : ''
      }
      selectGlossInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          console.log('name:' + data.name)
          this.dataForm.gloss = data.data.name
          this.productAttrId = data.data.productAttrId
          this.attrValue = data.data.id
          this.$set(this.searchData, '1-' + this.productAttrId, this.attrValue)
        } else {
          this.dataForm.gloss = ''
        }
      })
    },
    // 查询光泽值范围
    getGlossInfo() {
      let params = {}
      getGlossInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.glossList = data.data[0].options
        } else {
          this.glossList = ''
        }
      })
    },
    //根据产品编号匹配产品系列
    getNoSeries() {
      this.dataForm.productName = 'TIGER_' + this.dataForm.productNo
      if (this.dataForm.productNo.length > 4) {
        return false
      }
      let params = {
        productNo: this.dataForm.productNo
      }

      queryNoSeries(params).then(({ data }) => {
        if (data && data.code === 0 && data.serHierIdVo) {
          this.dataForm.productSeries = data.serHierIdVo.seriesId
          this.dataForm.industryId = data.serHierIdVo.industryId
        } else {
          this.dataForm.productSeries = ''
          this.dataForm.industryId = ''
        }
      }),
        queryNoColor(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.dataForm.colorSeries = data.colorId
          } else {
            this.dataForm.colorSeries = ''
          }
        })
    },
    init(id) {
      this.getAttrAndValueListInfo()
      this.getGlossInfo()
      //进来就调用获取所有下拉列
      this.dataForm.id = id || 0
      //  this.
      this.$refs.SidesLip.init(
        this.dataForm.id ? '自检' : '自检',
        '900px',
        'block'
      )
      this.getProductAttrValue()
      this.loading = false
      this.visible = true
      // console.log(this.$refs);

      this.$nextTick(() => {
        //清除缓存数据
        this.$refs['dataForm'].resetFields()
        this.dataForm.lAve = ''
        this.dataForm.aAve = ''
        this.dataForm.bAve = ''
        this.dataForm.l1 = ''
        this.dataForm.a1 = ''
        this.dataForm.b1 = ''
        this.dataForm.l2 = ''
        this.dataForm.a2 = ''
        this.dataForm.b2 = ''
        this.dataForm.l3 = ''
        this.dataForm.a3 = ''
        this.dataForm.b3 = ''
        this.searchData = {}
        if (this.dataForm.id) {
          sampleproduct({ id: this.dataForm.id }).then(({ data }) => {
            if (data && data.code === 0) {
              if (data.product.sampleType != '0') {
                // this.dataForm.isShowPlan = false
                this.dataForm.inspectionPlanData = []
              }
              this.dataForm.productId = data.product.id
              this.dataForm.productNo = data.product.productNo
              this.dataForm.productName = 'Tiger_' + data.product.productNo
              this.dataForm.makeFactory = data.product.makeFactory
              this.dataForm.picUrl = data.product.picUrl
              this.dataForm.hex = data.product.hex
              this.dataForm.qrUrl = data.product.qrUrl
              this.dataForm.ralColorNo = data.product.ralColorNo
              this.dataForm.gloss = data.product.gloss
              this.dataForm.glossValue = data.product.glossValue
              // this.dataForm.surface = data.product.surface
              // this.dataForm.colorSeries = data.product.colorSeries
              // this.dataForm.productSeries = data.product.productSeries
              // this.dataForm.productPerformance = data.product.poductPerformance
              // this.dataForm.otherPerformance = data.product.otherPerformance
              // this.dataForm.productCategory = data.product.productCategory
              // this.dataForm.productHierarchy = data.product.productHierarchy
              // this.dataForm.industryId = data.product.industryId
              this.dataForm.factoryId = data.product.factoryId
              this.dataForm.brandId = data.product.brandId
              this.dataForm.description = data.product.description
              this.dataForm.l1 = data.product.l1
              this.dataForm.a1 = data.product.a1
              this.dataForm.b1 = data.product.b1
              this.dataForm.l2 = data.product.l2
              this.dataForm.a2 = data.product.a2
              this.dataForm.b2 = data.product.b2
              this.dataForm.l3 = data.product.l3
              this.dataForm.a3 = data.product.a3
              this.dataForm.b3 = data.product.b3
              this.dataForm.lAve = data.product.lave
              this.dataForm.aAve = data.product.aave
              this.dataForm.bAve = data.product.bave
              this.dataForm.test = data.product.test
              this.dataForm.productOrder = data.product.productOrder
              this.dataForm.inspectionPlanData = data.product.inspectionPlanData.map((item) => {
                const keys = Object.keys(item);
                return {
                  name: keys[0],
                  data: item[keys[0]],
                  isEdit: false,
                }
              });
              // 获取属性
              this.searchData = data.resultMap
            }
          })
        }
      })
    },
    close() {
      console.log('2222222222222')
      this.performanceList = []
      // this.inspectionPlanData =[]
      this.dataForm.isShowPlan = true
      // this.dataForm.lAve ='';
      // this.dataForm.aAve ='';
      // this.dataForm.bAve ='';
      this.$refs['dataForm'].resetFields()
      this.$refs.SidesLip.sideClose()
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          var params = {
            searchData: this.searchData,
            id: this.dataForm.productId || undefined,
            productNo: this.dataForm.productNo,
            productName: this.dataForm.productName,
            makeFactory: this.dataForm.makeFactory,
            picUrl: this.dataForm.picUrl,
            qrUrl: this.dataForm.qrUrl,
            ralColorNo: this.dataForm.ralColorNo,
            gloss: this.dataForm.gloss,
            glossValue: this.dataForm.glossValue,
            // 'surface': this.dataForm.surface,
            // 'colorSeries': this.dataForm.colorSeries,
            // 'productSeries': this.dataForm.productSeries,
            // 'poductPerformance': this.dataForm.productPerformance,
            // 'otherPerformance': this.dataForm.otherPerformance,
            // 'productCategory': this.dataForm.productCategory,
            // 'productHierarchy': this.dataForm.productHierarchy,
            // 'industryId': this.dataForm.industryId,
            factoryId: '',
            brandId: '',
            test: '1',
            state: '0',
            description: this.dataForm.description,
            l1: this.dataForm.l1,
            a1: this.dataForm.a1,
            b1: this.dataForm.b1,
            l2: this.dataForm.l2,
            a2: this.dataForm.a2,
            b2: this.dataForm.b2,
            l3: this.dataForm.l3,
            a3: this.dataForm.a3,
            b3: this.dataForm.b3,
            lAve: this.dataForm.lAve,
            aAve: this.dataForm.aAve,
            bAve: this.dataForm.bAve,
            // 'test': this.dataForm.test,
            productOrder: this.dataForm.productOrder,
            sampleId: this.dataForm.id,
            solidifyId: this.dataForm.solidifyId,
            solTime: this.dataForm.solTime,
            temperature: this.dataForm.temperature,
            solidifyDesc: this.dataForm.solidifyDesc,
            productPerformanceS: this.dataForm.productPerformanceS,
            thickness: this.dataForm.thickness,
            bend: this.dataForm.bend,
            attack: this.dataForm.attack,
            delimit: this.dataForm.delimit,
            cupular: this.dataForm.cupular,
            other: this.dataForm.other,
            inspectionPlanData: this.dataForm.inspectionPlanData
          }
          var tick = !this.dataForm.productId
            ? selfInspection(params)
            : selfInspection(params)
          tick.then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  // this.visible = false;
                  this.dataLoading = false
                  this.$refs.SidesLip.sideClose()
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
              this.dataLoading = false
            }
          })
        }
      })
    },
    //上传产品图处理
    handleUploadSuccess(res, file, type) {
      this.dataForm.picUrl = res.data.fileUrl
    },
    //上传前对图片大小及格式进行判断
    beforeUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/bmp' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        this.$message.error('上传图片格式不支持!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 8MB!')
      }
      return isJPG && isLt2M
    },
    //获取行业选择表
    getIndustry() {
      let params = {}
      getIndustryList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.industryList = data.list
        } else {
          this.industryList = []
        }
      })
    },
    //获取色系选择表
    getColorSeries() {
      let params = {}
      getColorSeriesList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.colorSeriesList = data.list
        } else {
          this.colorSeriesList = []
        }
      })
    },
    //获取产品分类列表
    getProductCategoryList() {
      let params = {}
      getProductCategory(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.productCategoryList = data.list
        } else {
          this.productCategoryList = []
        }
      })
    },
    //获取其他性能
    getDictList() {
      let params = {
        type: '1'
      }
      getDict(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.otherPerformanceList = data.list
        } else {
          this.otherPerformanceList = []
        }
      })
    },
    //获取产品系列表
    getProductSeriesList() {
      let params = {}
      getProductSeries(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.productSeriesList = data.list
        } else {
          this.productSeriesList = []
        }
      })
    },

    //获取产品表面选项
    getProductSurfaceList() {
      let params = {
        type: '2'
      }
      getDict(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.productSurfaceList = data.list
        } else {
          this.productSurfaceList = []
        }
      })
    },
    //获取产品光泽选项
    getProductGlossList() {
      let params = {
        type: '3'
      }
      getDict(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.productGlossList = data.list
        } else {
          this.productGlossList = []
        }
      })
    },
    //获取产品体系
    getProductHierarchyList() {
      let params = {}
      getProductHierarchy(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.productHierarchyList = data.list
        } else {
          this.productHierarchyList = []
        }
      })
    },
    dataReceived(data) {
      // 收到了设备数据
      this.dataForm.picUrl = data.picUrl
      this.dataForm.lAve = data.lAve
      this.dataForm.aAve = data.aAve
      this.dataForm.bAve = data.bAve
      this.dataForm.l1 = data.l1
      this.dataForm.a1 = data.a1
      this.dataForm.b1 = data.b1
      this.dataForm.l2 = data.l2
      this.dataForm.a2 = data.a2
      this.dataForm.b2 = data.b2
      this.dataForm.l3 = data.l3
      this.dataForm.a3 = data.a3
      this.dataForm.b3 = data.b3
      this.$message.success('同步成功')
      this.loading = false

      if (data.secondsAgo) {
        this.$refs.inlineMessage.show(
          '读取设备成功: ' + getSecondsAgoMessage(data.secondsAgo)
        )
      }
      this.$forceUpdate()
    },
    planEdit(item) {
      item.isEdit = true
    },
  }
}
</script>
<style lang="scss">
.proDialog {
  /*.el-select .el-input .el-select__caret{*/
  /*display: none;*/
  /*}*/
}

.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .el-upload:hover {
    border-color: #409eff;
  }
}
</style>
<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 178px;
  text-align: center;
}

.ziti {
  font-size: 12px;
  margin: 0px;
  line-height: 25px;
}

.avatar {
  width: 178px;
  max-height: 178px;
  display: block;
}

.productLAB {
  margin: 0;
  padding: 0;
  margin-top: 5px;

  //margin-left: -50px;
  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;

    .title {
      display: inline-block;
      width: 100px;
      text-align: center;
      color: #606266;
      font-size: 700;
    }
  }
}

.w100 {
  width: 100px;
}

.w150 {
  width: 90px;
}

.mr10 {
  margin-right: 10px;
}

.collapse-title {
  flex: 1 0 90%;
  color: #4ab7bd;
  font-weight: bolder;
  text-align: center;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 18px;
}

.inspection-plan {
  width: 600px;
  height: 500px;
  margin-top: 20px;
  text-align: center;
  border: 1px solid #DCDFE6;

}

.inspection-plan .title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #DCDFE6;
  margin: 0;
  height: 38px;
  line-height: 38px;
}

.inspection-plan div span {
  display: inline-block;
  width: 50%;
  height: 38px;
  line-height: 38px;
  color: #606266;
  border-bottom: 1px solid #DCDFE6;
}

.inspection-plan .plan_content div:last-child span {
  border-bottom: none;
}

.inspection-plan div span:first-child {
  border-right: 1px solid #DCDFE6;
}

.inspection-plan .plan_content {
  height: calc(100% - 40px);
  overflow-y: scroll;
}

.plan_wrap {
  display: flex;
}

.plan_wrap input {
  height: 38px !important;
  line-height: 38px;

}

.plan_wrap .plan_row input {
  border-right: none;
}
</style>
