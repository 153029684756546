import request from '@/utils/request'

const saasUrl = "/tiger-admin/product/productnorule";

// 获取列表
export function list (params) {
  return request({
    url: '/product/productnorule/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/product/productnorule/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/product/productnorule/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/product/productnorule/update',
    method: 'post',
    data: params
  })
}

// 获取匹配数据
export function selectProductAttrInfo (params) {
  return request({
    url: `${saasUrl}/selectProductAttrInfo`,
    method: 'post',
    data: params
  })
}

// 获取标签匹配数据
export function selectProductLabelInfo (params) {
  return request({
    url: '/product/productnorule/selectProductLabelInfo',
    method: 'post',
    data: params
  })
}
// 修改
export function updateNoRuleAndAttrValue (params) {
  return request({
    url: '/product/productnorule/updateNoRuleAndAttrValue',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/product/productnorule/delete',
    method: 'post',
    data: params
  })
}
