import request from "@/utils/request";

const url = "/api/sample/sampleproduct";

// 获取列表
export function list(params) {
  return request({
    url: `${url}/list`,
    method: "get",
    params: params,
  });
}

// 获取列表
export function listuser(params) {
  return request({
    url: "/sample/sampleproduct/listByUser",
    method: "get",
    params: params,
  });
}

// 获取信息
export function info(params) {
  return request({
    url: "/sample/sampleproduct/info",
    method: "get",
    params: params,
  });
}

export function sampleproduct(params) {
  return request({
    url: `${url}/sampleproduct`,
    method: "get",
    params: params,
  });
}

// 添加
export function add(params) {
  return request({
    url: `${url}/save`,
    method: "post",
    data: params,
  });
}

// 修改
export function update(params) {
  return request({
    url: `${url}/update`,
    method: "post",
    data: params,
  });
}

// 添加
export function addSample(params) {
  return request({
    url: `${url}/addSample`,
    method: "post",
    data: params,
  });
}

// 修改
export function updateSample(params) {
  return request({
    url: "/sample/sampleproduct/updateSample",
    method: "post",
    data: params,
  });
}

// 删除
export function del(params) {
  return request({
    url: "/sample/sampleproduct/delete",
    method: "post",
    data: params,
  });
}

// 转正
export function choose(params) {
  return request({
    url: `${url}/choose`,
    method: "post",
    data: params,
  });
}

// 生成产品打样单
export function saveSampleProductByProduct(params) {
  return request({
    url: "/sample/sampleproduct/saveSampleProductByProduct",
    method: "post",
    data: params,
  });
}

// 生成产品打样单
export function saveNewSample(params) {
  return request({
    url: `${url}/saveNewSample`,
    method: "post",
    data: params,
  });
}

// 获取列表
export function sampleProductList(params) {
  return request({
    url: "/sample/sampleproduct/sampleProductList",
    method: "get",
    params: params,
  });
}

// 获取列表
export function querySampleProductList(params) {
  return request({
    url: "/sample/sampleproduct/querySampleProductList",
    method: "get",
    params: params,
  });
}
//自检操作
export function selfInspection(params) {
  return request({
    url: `${url}/selfInspection`,
    method: "post",
    data: params,
  });
}
// 自检提交操作
export function updateState(ids) {
  return request({
    url: `${url}/updateState`,
    method: "post",
    data: ids,
  });
}

//验证驳回操作
export function updateSampleProoductState(params) {
  return request({
    url: `${url}/updateSampleState`,
    method: "post",
    data: params,
  });
}

export function sampleproductJD(params) {
  return request({
    url: `${url}/sampleproductJD`,
    method: "get",
    params: params,
  });
}

export function sampleproductInfo(params) {
  return request({
    url: "/sample/sampleproduct/sampleproductInfo",
    method: "get",
    params: params,
  });
}

//获取固化详情的操作
export function recipeInfo(params) {
  return request({
    url: `${url}/recipeInfo`,
    method: "get",
    params: params,
  });
}

//获取打样单头部信息
export function orderAndProductInfo(params) {
  return request({
    url: `${url}/orderAndProductInfo`,
    method: "get",
    params: params,
  });
}

//获取色差值
export function getE00(params) {
  return request({
    url: `${url}/getE00`,
    method: "post",
    data: params,
  });
}

//获取色差值
export function getE00Info(params) {
  return request({
    url: `${url}/getE00Info`,
    method: "post",
    data: params,
  });
}

//作废
export function cancellation(params) {
  return request({
    url: `${url}/cancellation`,
    method: "get",
    params: params,
  });
}

//获取组份
export function findSampleType(params) {
  return request({
    url: `${url}/findSampleType`,
    method: "get",
    params: params,
  });
}

//获取底粉
export function findSampleTypeCa(params) {
  return request({
    url: "/sample/sampleproduct/findSampleTypeCa",
    method: "get",
    params: params,
  });
}
