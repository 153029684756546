<template>
  <sides-lip ref="SidesLip" @click="close()">
    <div slot="soltForm">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        size="small"
        ref="dataForm"
        label-width="120px"
        v-loading="dataLoading"
        style="width: 100%"
        element-loading-text=""
        element-loading-spinner="el-icon-loading"
      >
        <div style="display: flex">
          <div style="width: 70%">
            <el-form-item label="订单号" prop="sampleOrderNo">
              <el-input
                v-model="dataForm.sampleOrderNo"
                placeholder="请输入订单号"
                size="small"
                style="width: 80%"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="平台订单号" prop="thirdOrderNo" v-if="dataForm.orderSource == 1 || dataForm.orderSource == 2">
              <el-input v-model="dataForm.thirdOrderNo" placeholder="平台订单号"  size="small" style="width: 80%" disabled></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="产品号" prop="productNo">
              <el-input v-model="dataForm.productNo" placeholder="产品编号" size="small" @blur="getProutEntity()"
                        style="width: 80%" maxlength="30"></el-input>
            </el-form-item> -->
            <el-form-item label="产品号" prop="productNo">
              <el-input
                v-model="dataForm.productNo"
                placeholder="产品编号"
                size="small"
                @blur="getProutEntity()"
                style="width: 80%"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <el-form-item label="外部订单号" prop="thirdOrderNo">
              <el-input
                v-model="dataForm.thirdOrderNo"
                placeholder="外部订单号"
                size="small"
                style="width: 80%"
              ></el-input>
            </el-form-item>
            <el-form-item label="订单类型" prop="sampleType">
              <el-select
                v-model="dataForm.sampleType"
                size="small"
                placeholder="请选择订单类型"
                style="width: 80%"
                @change="getChangProductNo()"
              >
                <!-- <el-option label="新产品打样" value="0" size="small">新产品打样</el-option>
                <el-option label="样粉样板申请" value="1" size="small">样粉样板申请</el-option>
                <el-option label="配方调整" value="2" size="small">配方调整</el-option> -->
                <el-option
                  v-for="item in sampleTypes"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                  size="small"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品类别" prop="productCartegry">
              <el-select
                v-model="dataForm.productCartegry"
                size="small"
                placeholder="请选择打样分类"
                style="width: 80%"
              >
                <el-option label="金属粉" value="0" size="small"
                  >金属粉</el-option
                >
                <el-option label="普通粉" value="1" size="small"
                  >普通粉</el-option
                >
              </el-select>
            </el-form-item>
          </div>
          <!--          <div style="width: 30%;margin-top: 0px">-->
          <!--            <el-image v-if="dataForm.lAve!='' || dataForm.aAve!='' || dataForm.bAve!=''" style="width: 140px; height: 140px" :src="dataForm.picUrl" fit="contain"></el-image>-->
          <!--            <div v-else :style="{backgroundColor:dataForm.hex,height:'140px',width:'140px'}"></div>-->
          <!--          </div>-->
          <div style="width: 30%; margin-top: 0px; margin-left: 20px">
            <el-form-item label="" prop="picUrl" label-width="10px">
              <el-upload
                :disabled="true"
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="handleUploadSuccess"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="dataForm.picUrl"
                  :src="dataForm.picUrl"
                  class="avatar"
                />
                <div
                  v-else-if="!dataForm.picUrl && dataForm.hex"
                  :style="{
                    backgroundColor: dataForm.hex,
                    height: '178px',
                    minWidth: '178px'
                  }"
                ></div>
              </el-upload>
            </el-form-item>
          </div>
        </div>
        <div>
          <div style="display: flex">
            <div style="width: 100%">
              <el-form-item
                label="测量LAB值"
                v-if="dataForm.sampleType === '0'"
              >
                <div>
                  <!-- <el-button
                    size="small"
                    type="primary"
                    class="mr10"
                    @click="syncDevice()"
                    :loading="loading"
                    >数据同步</el-button
                  > -->

                  <read-device
                    @read="dataReceived"
                    @readError="loading = false"
                  />
                  <inline-message
                    ref="inlineMessage"
                    style="margin-top: 10px"
                  />
                </div>
              </el-form-item>
              <el-form-item :disabled="dataForm.sampleType === '0'">
                <span slot="label"
                  ><!--<label style="color:#FA3D1E">*</label> -->LAB值</span
                >
                <el-row>
                  <el-col :span="5">
                    <el-form-item prop="lAve">
                      <el-input
                        v-model="dataForm.lAve"
                        placeholder="L值"
                        class="w150 mr10"
                        size="small"
                        clearable
                        maxlength="30"
                        :disabled="dataForm.sampleType === '1'"
                        @input="getPicUrl()"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item prop="aAve">
                      <el-input
                        v-model="dataForm.aAve"
                        placeholder="A值"
                        class="w150 mr10"
                        size="small"
                        clearable
                        maxlength="30"
                        :disabled="dataForm.sampleType === '1'"
                        @input="getPicUrl()"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item prop="bAve">
                      <el-input
                        v-model="dataForm.bAve"
                        placeholder="B值"
                        class="w150 mr10"
                        size="small"
                        clearable
                        maxlength="30"
                        :disabled="dataForm.sampleType === '1'"
                        @input="getPicUrl()"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8"> </el-col>
                </el-row>
              </el-form-item>
            </div>
          </div>
          <div class="ces-main">
            <search-form
              ref="form"
              size="mini"
              labelWidth="120px"
              :searchData="searchData"
              :searchForm="searchForm"
              :loading="attrLoading"
              :notIncluded="notIncluded"
            ></search-form>
          </div>
          <div style="display: flex">
            <div style="width: 70%">
              <el-form-item label="光泽值" prop="glossValue">
                <el-input
                  v-model="dataForm.glossValue"
                  placeholder="光泽值"
                  size="small"
                  @input="selectGlossInfo()"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </div>
            <div style="width: 30%">
              <el-form-item label="" prop="gloss" label-width="20px">
                <el-select
                  v-model="dataForm.gloss"
                  size="small"
                  placeholder=""
                  style="width: 100%"
                  disabled="disabled"
                >
                  <el-option
                    v-for="item in productGlossList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    size="small"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <el-form-item label="">
            <el-collapse @change="handleChange">
              <el-collapse-item>
                <span class="collapse-title" slot="title">查看参考标准</span>
                <div style="display: flex">
                  <div style="width: 60%">
                    <template v-for="(item, index) in glossList">
                      <p v-if="index % 2 == 0" class="ziti">{{ item.label }}</p>
                    </template>
                  </div>
                  <div style="width: 60%">
                    <template v-for="(item, index) in glossList">
                      <p v-if="index % 2 == 1" class="ziti">{{ item.label }}</p>
                    </template>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
        </div>
        <div style="display: flex">
          <div style="width: 50%">
            <el-form-item label="膜厚" prop="thickness">
              <el-select
                v-model="dataForm.thickness"
                size="small"
                placeholder="请选择膜厚"
                style="width: 100%"
              >
                <el-option label="薄涂(50-70微米)" value="0" size="small"
                  >薄涂(50-70微米)</el-option
                >
                <el-option label="正常(60-80微米)" value="1" size="small"
                  >正常(60-80微米)</el-option
                >
                <el-option label="厚涂(70-90微米)" value="2" size="small"
                  >厚涂(70-90微米)</el-option
                >
                <el-option label="厚涂(90-120微米)" value="3" size="small"
                  >厚涂(90-120微米)</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="样板数量" prop="modelSum">
              <el-input
                v-model="dataForm.modelSum"
                placeholder="样板数量"
                size="small"
                ><template slot="append">张</template></el-input
              >
            </el-form-item>
            <el-form-item label="样粉数量" prop="materialSum">
              <el-input
                v-model="dataForm.materialSum"
                placeholder="样粉数量"
                size="small"
                ><template slot="append">kg</template></el-input
              >
            </el-form-item>
            <el-form-item label="样板材质" prop="basicMaterial">
              <el-select
                v-model="dataForm.basicMaterial"
                size="small"
                placeholder="请选择样板材质"
                style="width: 100%"
              >
                <el-option label="铝板" value="0" size="small">铝板</el-option>
                <el-option label="钢板" value="1" size="small">钢板</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否提供样件" prop="hasSample">
              <el-select
                v-model="dataForm.hasSample"
                size="small"
                placeholder="是否提供样件"
                style="width: 100%"
              >
                <el-option label="无" value="0" size="small">无</el-option>
                <el-option label="有" value="1" size="small">有</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="样件是否寄回" prop="isBacksend">
              <el-select
                v-model="dataForm.isBacksend"
                size="small"
                placeholder="样件是否寄回"
                style="width: 100%"
              >
                <el-option label="不寄回" value="0" size="small"
                  >不寄回</el-option
                >
                <el-option label="寄回" value="1" size="small">寄回</el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="width: 50%">
            <el-form-item label="预计用量" prop="dosage">
              <el-input
                v-model="dataForm.dosage"
                placeholder="预计用量"
                size="small"
                ><template slot="append">kg</template></el-input
              >
            </el-form-item>
            <el-form-item label="预计价格" prop="price">
              <el-input
                v-model="dataForm.price"
                placeholder="预计价格"
                size="small"
                ><template slot="append">元</template></el-input
              >
            </el-form-item>
            <el-form-item label="测色标准" prop="testColorStandard">
              <el-select
                v-model="dataForm.testColorStandard"
                size="small"
                placeholder="请选择测色标准"
                style="width: 100%"
              >
                <el-option label="目测" value="0" size="small">目测</el-option>
                <el-option label="仪器测" value="1" size="small"
                  >仪器测</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="测色设备型号" prop="testDeviceModel">
              <el-select
                v-model="dataForm.testDeviceModel"
                size="small"
                placeholder="请选择测色设备型号"
                style="width: 100%"
              >
                <el-option label="Datacolor" value="0" size="small"
                  >Datacolor</el-option
                >
                <el-option label="BYK" value="1" size="small">BYK</el-option>
                <el-option label="X-RITE" value="2" size="small"
                  >X-RITE</el-option
                >
                <el-option label="其他" value="3" size="small">其他</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="色差范围" prop="e2000">
              <el-select
                v-model="dataForm.e2000"
                size="small"
                placeholder="请选择色差范围"
                style="width: 100%"
              >
                <el-option
                  v-for="item in e2000List"
                  :key="item.e2000"
                  :label="item.value"
                  :value="item.e2000"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="交付日期" prop="deliverDate">
              <el-date-picker
                @change="handleChange"
                v-model="dataForm.deliverDate"
                type="date"
                placeholder="选择交付时间"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label=""> </el-form-item>
          </div>
        </div>
        <el-form-item label="客户列表">
          <!-- <el-select v-model="dataForm.customerId" size="small" placeholder="请选择客户" style="width: 100%" @change="setCustomer(dataForm.customerId)">
                <el-option
                        v-for="item in customerList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
              </el-select> -->
          <el-autocomplete
            v-model="customerName"
            :fetch-suggestions="querySearch"
            @select="handleSelect"
            placeholder="请输入客户名称"
            :trigger-on-focus="true"
            size="small"
            style="width: 300px"
          >
            <template slot-scope="{ item }">
              <div>
                <span style="float: left">{{ item.name }}</span>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <div style="display: flex">
          <div style="width: 50%">
            <el-form-item label="客户" prop="company">
              <el-input
                v-model="dataForm.company"
                placeholder="客户"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contactsName">
              <el-input
                v-model="dataForm.contactsName"
                placeholder="联系人"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="收件地址" prop="contactsAddress">
              <el-input
                v-model="dataForm.contactsAddress"
                placeholder="收件地址"
                size="small"
              ></el-input>
            </el-form-item>
          </div>
          <div style="width: 50%">
            <el-form-item label="客户级别" prop="contactsLevel">
              <el-input
                v-model="dataForm.contactsLevel"
                placeholder="客户级别"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="contactsPhone">
              <el-input
                v-model="dataForm.contactsPhone"
                placeholder="联系电话"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="输入人员">
              <el-select v-model="dataForm.inputUserId" filterable placeholder="请选择">
                <el-option
                  v-for="item in userList"
                  :key="item.userId"
                  :label="item.username"
                  :value="item.userId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="其他备注" prop="description">
          <div style="margin: 5px 0"></div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="其他备注"
            v-model="dataForm.description"
            maxlength="150"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="soltFooter">
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          @click="dataFormSubmit()"
          size="small"
          :disabled="dataLoading"
          >确定</el-button
        >
      </span>
    </div>
  </sides-lip>
</template>

<script>
import { getSecondsAgoMessage } from '@/utils/momentUtil'

import ReadDevice from '@/components/readDevice.vue'
import {
  queryNoSeries,
  queryNoColor,
  selectGloss,
  validateProductNo
} from '@/api/modules/product'
import {
  info,
  add,
  update,
  productInfo,
  getPicUrl
} from '@/api/modules/sampleorder'
import { getSampleTypes } from '@/api/modules/sampleconfig'
import Tinymce from '@/components/Tinymce'
import { getToken } from '@/utils/auth'
import SidesLip from '@/components/SidesLip'
import SearchForm from '@/components/form.vue'
import {
  getAttrAndValueListInfo,
  getGlossInfo,
  selectGlossInfo
} from '@/api/modules/productattr'
import {
  getProductAttrValue,
  getSeriesPerformanceUse
} from '@/api/modules/productattrvalue'
import { selectProductAttrInfo } from '@/api/modules/productnorule'
import { getcustomerList } from '@/api/modules/customer'
import InlineMessage from '../../components/inlineMessage.vue'
import { getUsernames } from '@/api/user'
export default {
  components: {
    Tinymce,
    SidesLip,
    SearchForm,
    ReadDevice,
    InlineMessage
  },
  data() {
    var validateProductNoOnly = (rule, value, callback) => {
      if (
        (this.dataForm.productNo == '0' ||
          this.dataForm.productNo == '' ||
          this.dataForm.productNo == null) &&
        this.dataForm.sampleType != '0'
      ) {
        return callback(new Error('请输入产品号'))
      }
      //校验产品编号唯一
      if (this.dataForm.sampleType == '0') {
        if (
          this.dataForm.productNo != '0' &&
          this.dataForm.productNo != '' &&
          this.dataForm.productNo != null
        ) {
          let params = {
            productNo: this.dataForm.productNo,
            id: this.dataForm.id
          }
          validateProductNo(params).then(({ data }) => {
            if (data && data.code === 0) {
              if (data.merchant != null) {
                return callback(new Error('商品编号已经存在，请重新填写'))
              } else {
                return callback()
              }
            } else {
              return callback(new Error(data.msg))
            }
          })
        } else {
          return callback()
        }
      } else {
        if (null == this.dataForm.productNo) {
          return callback(new Error('需要输入产品号'))
        }
        let params = {
          productNo: this.dataForm.productNo
        }
        console.log('获取到的编号' + params)
        productInfo(params).then(({ data }) => {
          if (null == data || data.code != 0) {
            return callback(new Error(data.msg))
          } else {
            return callback()
          }
        })
      }
    }
    return {
      notIncluded: [],
      searchForm: [],
      searchData: {},
      productAttrId: '',
      attrValue: '',
      attrLoading: false,
      glossList: [],
      biaoZhun: false,
      showLAB: false,
      loading: false,
      action: `/tiger-admin/sys/oss/upload?token=${getToken()}`,
      visible: false,
      dataLoading: false,
      productLAB: {
        title: ['角度', 'L值', 'A值', 'B值'],
        data: ['25度', '45度', '75度']
      },
      dataForm: {
        id: 0,
        sampleOrderNo: '',
        productNo: '',
        company: '',
        contactsName: '',
        contactsPhone: '',
        contactsAddress: '',
        contactsLevel: '',
        sampleType: '0',
        sampleMaterial: '',
        sysProductId: '',
        picUrl: '',
        ralColorNo: '',
        gloss: '',
        glossValue: '',
        hex: '',
        surface: '',
        industryId: '',
        productCartegry: '1',
        productSeries: '',
        lAve: '',
        aAve: '',
        bAve: '',
        dosage: '',
        price: '',
        materialSum: '',
        modelSum: '',
        basicMaterial: '',
        thickness: '',
        testColorStandard: '',
        testDeviceModel: '',
        e2000: '',
        isBacksend: '',
        hasSample: '',
        factoryId: '',
        brandId: '',
        description: '',
        state: '',
        orderSource: '',
        thirdOrderNo: '',
        deliverDate: '',
        productCategory: 0,
        productPerformance: '',
        otherPerformance: '',
        productHierarchy: '',
        colorSeries: '',
        customerId: '',
        inputUserId: ''
      },
      userList: [],
      customerList: [],
      customerName: '',
      deviceList: [],
      performanceList: [],
      industryList: [],
      colorSeriesList: [],
      productCategoryList: [],
      otherPerformanceList: [],
      productSeriesList: [],
      productGlossList: [],
      productSurfaceList: [],
      productHierarchyList: [],
      sampleTypes: [],
      e2000List: [
        {
          e2000: 0,
          value: '小于0.3'
        },
        {
          e2000: 1,
          value: '小于0.5'
        },
        {
          e2000: 2,
          value: '小于1.0'
        },
        {
          e2000: 3,
          value: '小于2.0'
        }
      ],
      dataRule: {
        productNo: [
          // {required: true, message: '产品编号不能为空', trigger: 'blur'},
          { validator: validateProductNoOnly, trigger: 'blur' }
        ],
        sampleOrderNo: [
          { required: true, message: '订单号不能为空', trigger: 'blur' }
        ],
        sampleType: [
          { required: true, message: '订单类型不能为空', trigger: 'blur' }
        ],
        materialSum: [
          { required: true, message: '样粉重量必填', trigger: 'blur' }
        ]
        /*lAve: [
            {required: true, message: 'L平均值不能为空', trigger: 'blur'}
          ],
          aAve: [
            {required: true, message: 'A平均值不能为空', trigger: 'blur'}
          ],
          bAve: [
            {required: true, message: 'B平均值不能为空', trigger: 'blur'}
          ]*/
      }
    }
  },
  created() {},
  beforeDestroy() {},
  methods: {
    //系列联动性能标准和应用场景
    getSeriesPerformanceUse(e) {
      let params = {
        id: e
      }
      getSeriesPerformanceUse(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.productPerformance = data.list.performanceId
          this.dataForm.industryId = data.list.industryId
        }
      })
    },
    getProductAttrValue() {
      let params = {}
      getProductAttrValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          for (var i = 0; i < data.list.length; i++) {
            if (data.list[i].productAttrType === '1') {
              //表面
              this.productSurfaceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '3') {
              //系列
              this.productSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '6') {
              //主题
              this.productHierarchyList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '2') {
              //色系
              this.colorSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '7') {
              //性能
              this.performanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '4') {
              //应用
              this.industryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '8') {
              //功能
              this.otherPerformanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '5') {
              //分类
              this.productCategoryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '9') {
              //光泽
              this.productGlossList = data.list[i].productAttrValue
            }
          }
        }
      })
    },

    getAttrAndValueListInfo: function () {
      this.notIncluded = []
      this.searchData = {}
      this.searchForm = []
      this.tableCols = []
      var params = {
        type: 'detail',
        isUniversal: '0'
      }
      getAttrAndValueListInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          data.data.forEach((e) => {
            var obj = { type: '', label: '', width: '180px' }
            if (e.type == '1') {
              // 如果是单选
              obj.type = 'Select'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.label = e.value
            }
            if (e.type == '2') {
              obj.type = 'SelectMultiple'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
            }
            if (e.type == '3') {
              obj.type = 'Cascader'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
            }
            obj.label = e.label
            this.$set(this.searchData, e.productAttrId, e.value)
            this.searchForm.push(obj)
          })
        } else {
          this.$message.error(data.msg)
          this.loading2 = false
        }
      })
    },

    handleChange(val) {
      console.log(val)
    },
    //LAB值按钮隐藏显示
    isShowLAB() {
      this.showLAB = !this.showLAB
    },
    isBiaoZhun() {
      this.biaoZhun = !this.biaoZhun
    },
    //根据光泽测试值显示光泽范围名称
    getSelectGloss() {
      let params = {
        glossValue: this.dataForm.glossValue
      }
      selectGloss(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.gloss = data.DictList.dictId
        } else {
          this.dataForm.gloss = ''
        }
      })
    },
    //根据光泽测试值显示光泽范围名称
    selectGlossInfo() {
      this.dataForm.glossValue = this.dataForm.glossValue.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
      this.dataForm.glossValue = this.dataForm.glossValue.replace(
        /\.{2,}/g,
        '.'
      ) //只保留第一个. 清除多余的
      this.dataForm.glossValue = this.dataForm.glossValue
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
      this.dataForm.glossValue = this.dataForm.glossValue.replace(
        /^(\-)*(\d+)\.(\d).*$/,
        '$1$2.$3'
      ) //只能输入两个小数
      if (
        this.dataForm.glossValue.indexOf('.') < 0 &&
        this.dataForm.glossValue != ''
      ) {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        this.dataForm.glossValue = parseFloat(this.dataForm.glossValue)
      }
      this.$set(this.searchData, '1-' + this.productAttrId, '')
      let params = {
        glossValue: this.dataForm.glossValue
          ? parseInt(this.dataForm.glossValue)
          : ''
      }
      selectGlossInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          console.log('name:' + data.name)
          this.dataForm.gloss = data.data.name
          this.productAttrId = data.data.productAttrId
          this.attrValue = data.data.id
          this.$set(this.searchData, '1-' + this.productAttrId, this.attrValue)
        } else {
          this.dataForm.gloss = ''
        }
      })
    },
    // 查询光泽值范围
    getGlossInfo() {
      let params = {}
      getGlossInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.glossList = data.data[0].options
        } else {
          this.glossList = ''
        }
      })
    },
    //根据产品编号匹配产品系列
    getNoSeries() {
      console.log('11')
      this.dataForm.productName = 'TIGER_' + this.dataForm.productNo
      if (this.dataForm.productNo.length > 4) {
        return false
      }

      let params = {
        productNo: this.dataForm.productNo
      }

      queryNoSeries(params).then(({ data }) => {
        if (data && data.code === 0 && data.serHierIdVo) {
          this.dataForm.productSeries = data.serHierIdVo.seriesId
          this.dataForm.industryId = data.serHierIdVo.industryId
        } else {
          this.dataForm.productSeries = ''
          this.dataForm.industryId = ''
        }
      }),
        queryNoColor(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.dataForm.colorSeries = data.colorId
          } else {
            this.dataForm.colorSeries = ''
          }
        })
    },
    //根据产品编号匹配色系
    getNoColor() {},
    getAttrResultMap() {
      let params = {
        brandId: this.dataForm.brandId,
        productNo: this.dataForm.productNo,
        searchData: this.searchData
      }
      selectProductAttrInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.searchData = data.resultMap
        }
      })
    },

    //根据产品号获取到产品具体信息
    getProutEntity() {
      //如果是新品打样，不需要获取产品信息，判断产品号是否唯一
      if (this.dataForm.sampleType == '0') {
        if (
          null != this.dataForm.productNo &&
          '' != this.dataForm.productNo &&
          this.dataForm.productNo != '0'
        ) {
          let params = {
            productNo: this.dataForm.productNo,
            id: this.dataForm.id
          }
          validateProductNo(params).then(({ data }) => {
            if (data && data.code === 0) {
              if (data.merchant != null) {
                this.$message.error('商品编号已经存在，请重新填写')
                return
              } else {
                this.getAttrResultMap()
                return
              }
            } else {
              this.$message.error(data.msg)
              return
            }
          })
        }
      } else {
        if (null == this.dataForm.productNo || '' == this.dataForm.productNo) {
          this.$message.error('需要输入产品号')
          return
        }
        let params = {
          productNo: this.dataForm.productNo
        }
        console.log('获取到的编号' + params)
        productInfo(params).then(({ data }) => {
          if (data && data.code === 0 && data.productEntity) {
            this.dataForm.sysProductId = data.productEntity.id
            this.dataForm.lAve = data.productEntity.lave
            this.dataForm.aAve = data.productEntity.aave
            this.dataForm.bAve = data.productEntity.bave
            this.dataForm.l1 = data.productEntity.l1
            this.dataForm.a1 = data.productEntity.a1
            this.dataForm.b1 = data.productEntity.b1
            this.dataForm.l2 = data.productEntity.l2
            this.dataForm.a2 = data.productEntity.a2
            this.dataForm.b2 = data.productEntity.b2
            this.dataForm.l3 = data.productEntity.l3
            this.dataForm.a3 = data.productEntity.a3
            this.dataForm.b3 = data.productEntity.b3
            this.dataForm.gloss = data.productEntity.gloss
            this.dataForm.glossValue = data.productEntity.glossValue
            this.dataForm.picUrl = data.productEntity.picUrl
            this.dataForm.productNo = data.productEntity.productNo
            // 获取属性
            this.searchData = data.resultMap
          } else {
            this.$message.error(data.msg)
            this.dataForm.sysProductId = ''
            this.dataForm.glossValue = ''
            this.dataForm.gloss = ''
            this.dataForm.lAve = ''
            this.dataForm.aAve = ''
            this.dataForm.bAve = ''
            this.dataForm.l1 = ''
            this.dataForm.a1 = ''
            this.dataForm.b1 = ''
            this.dataForm.l2 = ''
            this.dataForm.a2 = ''
            this.dataForm.b2 = ''
            this.dataForm.l3 = ''
            this.dataForm.a3 = ''
            this.dataForm.b3 = ''
            this.dataForm.productNo = ''
          }
        })
      }
    },
    //点击新品打样时除了产品号不出现所有都出现
    getChangProductNo() {
      this.getProutEntity()
      this.dataForm.surface = ''
      this.dataForm.productSeries = ''
      this.dataForm.productCategory = ''
      this.dataForm.productPerformance = ''
      this.dataForm.colorSeries = ''
      this.dataForm.industryId = ''
      this.dataForm.productHierarchy = ''
      this.dataForm.otherPerformance = ''
      this.dataForm.glossValue = ''
      this.dataForm.gloss = ''
      this.dataForm.lAve = ''
      this.dataForm.aAve = ''
      this.dataForm.bAve = ''
    },
    //选择设备显示在页面
    handleCommand(command) {
      //  console.log(command);
      //this.$message('click on item ' + command);
      this.device = command
    },
    getPicUrl() {
      var params = {
        lAve: this.dataForm.lAve || 0,
        aAve: this.dataForm.aAve || 0,
        bAve: this.dataForm.bAve || 0
      }
      getPicUrl(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.picUrl = data.picUrl
        }
      })
    },

    init(id) {
      this.getAttrAndValueListInfo()
      this.getGlossInfo()
      //进来就调用获取所有下拉列表
      this.dataForm.picUrl = ''
      this.dataForm.id = id || 0
      this.$refs.SidesLip.init(
        this.dataForm.id ? '编辑' : '新增',
        '800px',
        'block'
      )
      this.getProductAttrValue()
      this.getSampleTypes()
      this.getcustomerList()
      this.getUsernames()
      this.customerName = ''
      this.loading = false
      this.visible = true
      this.$nextTick(() => {
        //清除缓存数据
        this.$refs['dataForm'].resetFields()
        this.dataForm.lAve = ''
        this.dataForm.aAve = ''
        this.dataForm.bAve = ''
        this.dataForm.l1 = ''
        this.dataForm.a1 = ''
        this.dataForm.b1 = ''
        this.dataForm.l2 = ''
        this.dataForm.a2 = ''
        this.dataForm.b2 = ''
        this.dataForm.l3 = ''
        this.dataForm.a3 = ''
        this.dataForm.b3 = ''
        this.dataForm.hex = ''
        if (this.dataForm.id) {
          info({ id: this.dataForm.id }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.sampleOrderNo = data.sampleOrder.sampleOrderNo
              this.dataForm.productNo = data.sampleOrder.productNo
              this.dataForm.company = data.sampleOrder.company
              this.dataForm.contactsName = data.sampleOrder.contactsName
              this.dataForm.contactsPhone = data.sampleOrder.contactsPhone
              this.dataForm.contactsAddress = data.sampleOrder.contactsAddress
              this.dataForm.contactsLevel = data.sampleOrder.contactsLevel
              this.dataForm.sampleType = data.sampleOrder.sampleType
              this.dataForm.sysProductId = data.sampleOrder.sysProductId
              this.dataForm.picUrl = data.sampleOrder.picUrl
              this.dataForm.ralColorNo = data.sampleOrder.ralColorNo
              this.dataForm.gloss = data.sampleOrder.gloss
              this.dataForm.glossValue = data.sampleOrder.glossValue
              this.dataForm.hex = data.sampleOrder.hex
              this.dataForm.productCartegry = data.sampleOrder.productCartegry
              this.dataForm.lAve = data.sampleOrder.lAve
              this.dataForm.aAve = data.sampleOrder.aAve
              this.dataForm.bAve = data.sampleOrder.bAve
              this.dataForm.l1 = data.sampleOrder.l1
              this.dataForm.a1 = data.sampleOrder.a1
              this.dataForm.b1 = data.sampleOrder.b1
              this.dataForm.l2 = data.sampleOrder.l2
              this.dataForm.a2 = data.sampleOrder.a2
              this.dataForm.b2 = data.sampleOrder.b2
              this.dataForm.l3 = data.sampleOrder.l3
              this.dataForm.a3 = data.sampleOrder.a3
              this.dataForm.b3 = data.sampleOrder.b3
              this.dataForm.dosage = data.sampleOrder.dosage
              this.dataForm.price = data.sampleOrder.price
              this.dataForm.materialSum = data.sampleOrder.materialSum
              this.dataForm.modelSum = data.sampleOrder.modelSum
              this.dataForm.basicMaterial = data.sampleOrder.basicMaterial
              this.dataForm.thickness = data.sampleOrder.thickness
              this.dataForm.testColorStandard =
                data.sampleOrder.testColorStandard
              this.dataForm.testDeviceModel = data.sampleOrder.testDeviceModel
              this.dataForm.e2000 = data.sampleOrder.e2000
              this.dataForm.isBacksend = data.sampleOrder.isBacksend
              this.dataForm.hasSample = data.sampleOrder.hasSample
              this.dataForm.factoryId = data.sampleOrder.factoryId
              this.dataForm.brandId = data.sampleOrder.brandId
              this.dataForm.description = data.sampleOrder.description
              this.dataForm.state = data.sampleOrder.state
              this.dataForm.orderSource = data.sampleOrder.orderSource
              this.dataForm.thirdOrderNo = data.sampleOrder.thirdOrderNo
              this.dataForm.deliverDate = data.sampleOrder.deliverDate
              this.dataForm.inputUserId = data.sampleOrder.inputUserId
              // 获取属性
              this.searchData = data.resultMap
            }
          })
        }
      })
    },
    close() {
      this.performanceList = []
      //清除缓存数据
      this.$refs['dataForm'].resetFields()
      this.$refs.SidesLip.sideClose()
    },
    // 表单提交
    dataFormSubmit() {
      if (
        this.dataForm.sampleType != '0' &&
        null == this.dataForm.sysProductId &&
        '' == this.dataForm.sysProductId
      ) {
        this.$message.error('产品信息不存在')
        return
      }

      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          var params = {
            searchData: this.searchData,
            id: this.dataForm.id || undefined,
            sampleOrderNo: this.dataForm.sampleOrderNo,
            productNo: this.dataForm.productNo,
            company: this.dataForm.company,
            sysProductId: this.dataForm.sysProductId,
            contactsName: this.dataForm.contactsName,
            contactsPhone: this.dataForm.contactsPhone,
            contactsLevel: this.dataForm.contactsLevel,
            contactsAddress: this.dataForm.contactsAddress,
            picUrl: this.dataForm.picUrl,
            qrUrl: this.dataForm.qrUrl,
            ralColorNo: this.dataForm.ralColorNo,
            gloss: this.dataForm.gloss,
            glossValue: this.dataForm.glossValue,
            // 'surface': this.dataForm.surface,
            // 'colorSeries': this.dataForm.colorSeries,
            // 'productSeries': this.dataForm.productSeries,
            // 'performance': this.dataForm.productPerformance,
            // 'otherPerformance': this.dataForm.otherPerformance,
            productCartegry: this.dataForm.productCartegry,
            // 'productHierarchy': this.dataForm.productHierarchy,
            // 'application': this.dataForm.industryId,
            description: this.dataForm.description,
            dosage: this.dataForm.dosage,
            price: this.dataForm.price,
            materialSum: this.dataForm.materialSum,
            modelSum: this.dataForm.modelSum,
            basicMaterial: this.dataForm.basicMaterial,
            thickness: this.dataForm.thickness,
            testColorStandard: this.dataForm.testColorStandard,
            testDeviceModel: this.dataForm.testDeviceModel,
            e2000: this.dataForm.e2000,
            isBacksend: this.dataForm.isBacksend,
            hasSample: this.dataForm.hasSample,
            state: this.dataForm.state,
            sampleType: this.dataForm.sampleType,
            orderSource: this.dataForm.orderSource,
            thirdOrderNo: this.dataForm.thirdOrderNo,
            deliverDate: this.dataForm.deliverDate,
            inputUserId: this.dataForm.inputUserId,
            lAve: this.dataForm.lAve,
            aAve: this.dataForm.aAve,
            bAve: this.dataForm.bAve
          }
          // console.log(this.dataForm.id)
          var tick = !this.dataForm.id ? add(params) : update(params)
          tick.then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  // this.visible = false;
                  this.dataLoading = false
                  this.$emit('refreshDataList')
                  this.$refs.SidesLip.sideClose()
                }
              })
            } else {
              this.$message.error(data.msg)
              this.dataLoading = false
            }
          })
        }
      })
    },
    //上传产品图处理
    handleUploadSuccess(res, file, type) {
      this.dataForm.picUrl = res.data.fileUrl
    },
    //上传前对图片大小及格式进行判断
    beforeUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/bmp' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        this.$message.error('上传图片格式不支持!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 8MB!')
      }
      return isJPG && isLt2M
    },
    //获取客户列表
    getcustomerList() {
      let params = {}
      getcustomerList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.customerList = data.list
        } else {
          this.customerList = []
        }
      })
    },
    //获取客户列表
    getUsernames() {
      let params = {}
      getUsernames(params).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.userList = data.data
        } else {
          this.customerList = []
        }
      })
    },
    setCustomer(customerId) {
      console.log(customerId)
      for (var x in this.customerList) {
        if (this.customerList[x].id == customerId) {
          this.customerName = this.customerList[x].name
          this.dataForm.company = this.customerList[x].name
          this.dataForm.contactsName = this.customerList[x].contactsName
          this.dataForm.contactsPhone = this.customerList[x].contactsPhone
          this.dataForm.contactsAddress = this.customerList[x].contactsAddress
          this.dataForm.contactsLevel = this.customerList[x].level
        }
      }
    },

    //获取订单类型
    getSampleTypes() {
      let params = {}
      getSampleTypes(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.sampleTypes = data.list
        } else {
          this.sampleTypes = []
        }
      })
    },

    // querySearch(queryString, cb) {
    //   if(queryString){
    //     setTimeout(() =>{
    //       let params = {"name":queryString};
    //       getcustomerList(params).then(({ data }) => {
    //         if (data && data.code === 0) {
    //             var restaurants = data.list;
    //             restaurants.forEach(v => {
    //               v.value = v.name
    //             })

    //             if(restaurants.length <= 0){
    //               return this.$message.error("没有匹配到相关客户");
    //             }
    //             cb(restaurants);
    //         }
    //       })
    //     },1000);
    //   }
    // },
    querySearch(queryString, cb) {
      var customerList = this.customerList
      var results = queryString
        ? customerList.filter(this.createStateFilter(queryString))
        : customerList
      cb(results)
    },
    createStateFilter(queryString) {
      return (state) => {
        return state.name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0
      }
    },
    handleSelect(item) {
      //console.log(item);
      var id = item.id
      this.customerName = item.name
      for (var x in this.customerList) {
        if (this.customerList[x].id == id) {
          this.dataForm.company = this.customerList[x].name
          this.dataForm.contactsName = this.customerList[x].contactsName
          this.dataForm.contactsPhone = this.customerList[x].contactsPhone
          this.dataForm.contactsAddress = this.customerList[x].contactsAddress
          this.dataForm.contactsLevel = this.customerList[x].level
        }
      }
    },
    dataReceived(data) {
      // 收到了设备数据
      this.dataForm.picUrl = data.picUrl
      this.dataForm.lAve = data.lAve
      this.dataForm.aAve = data.aAve
      this.dataForm.bAve = data.bAve
      this.dataForm.l1 = data.l1
      this.dataForm.a1 = data.a1
      this.dataForm.b1 = data.b1
      this.dataForm.l2 = data.l2
      this.dataForm.a2 = data.a2
      this.dataForm.b2 = data.b2
      this.dataForm.l3 = data.l3
      this.dataForm.a3 = data.a3
      this.dataForm.b3 = data.b3
      this.$message.success('同步成功')
      this.loading = false

      if (data.secondsAgo) {
        this.$refs.inlineMessage.show(
          '读取设备成功: ' + getSecondsAgoMessage(data.secondsAgo)
        )
      }
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss">
.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 20px;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
}
</style>
<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 178px;
  text-align: center;
}

.ziti {
  font-size: 12px;
  margin: 0px;
  line-height: 25px;
}

.avatar {
  width: 178px;
  max-height: 178px;
  display: block;
}

.productLAB {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  margin-left: -50px;
  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
    .title {
      display: inline-block;
      width: 100px;
      text-align: center;
      color: #606266;
      font-size: 700;
    }
  }
}

.w100 {
  width: 100px;
}
.w150 {
  width: 119px;
}
.mr10 {
  margin-right: 5px;
}
.collapse-title {
  flex: 1 0 90%;
  color: #4ab7bd;
  font-weight: bolder;
  text-align: center;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 18px;
}
</style>
