var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_c('el-dialog',{attrs:{"title":!_vm.dataForm.id ? '查看配方' : '查看配方',"close-on-click-modal":false,"visible":_vm.recipeListVisible,"append-to-body":""},on:{"update:visible":function($event){_vm.recipeListVisible=$event}}},[_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.dataListLoading),expression:"dataListLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.dataList,"border":""}},[_c('el-table-column',{attrs:{"prop":"materialNo","header-align":"center","align":"center","label":"材料编号"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.componentId != null || scope.row.productId != null
              )?_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.init(
                  scope.row.componentId,
                  scope.row.productId,
                  scope.row.sampleProductId
                )}}},[_vm._v(_vm._s(scope.row.materialNo))]):_vm._e(),(
                scope.row.componentId == null && scope.row.productId == null
              )?_c('label',[_vm._v(_vm._s(scope.row.materialNo))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"materialName","header-align":"center","align":"center","label":"原料名称"}}),_c('el-table-column',{attrs:{"prop":"value","header-align":"center","align":"center","label":"计量值"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm._f("numFilter")(scope.row.value)))])]}}])}),_c('el-table-column',{attrs:{"prop":"units","header-align":"center","align":"center","label":"单位"}}),_c('el-table-column',{attrs:{"prop":"rate","header-align":"center","align":"center","label":"计量百分比"}})],1)],1),(_vm.dataCipeList != null && _vm.dataCipeList.length > 0)?_c('div',[_c('p',[_vm._v("邦定工艺")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.dataCipeList,"border":""}},[_c('el-table-column',{attrs:{"prop":"addStart","header-align":"center","align":"center","label":"add start开始"}}),_c('el-table-column',{attrs:{"prop":"addBonding","header-align":"center","align":"center","label":"add bonding过程"}}),_c('el-table-column',{attrs:{"prop":"rpm","header-align":"center","align":"center","label":"Rpm 速度"}}),_c('el-table-column',{attrs:{"prop":"temp","header-align":"center","align":"center","label":"Temp 温度"}}),_c('el-table-column',{attrs:{"prop":"time","header-align":"center","align":"center","label":"Time 时间"}}),_c('el-table-column',{attrs:{"prop":"cocktail","header-align":"center","align":"center","label":"cocktail 干混"}})],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }