<template>
  <el-form
    :model="dataForm"
    :rules="dataRule"
    size="small"
    ref="dataForm"
    label-width="160px"
    v-loading="dataLoading"
    style="width: 100%"
    element-loading-spinner="el-icon-loading"
  >
    <el-form-item>
      <span style="display: inline-block" slot="label">
        <el-popconfirm
          :confirm-button-text="$t('view_x', { x: $t('rules').toLowerCase() })"
          :cancel-button-text="$t('cancel')"
          icon="el-icon-info"
          :title="$t('product_number_must_respect_rules')"
          @onConfirm="dialogVisible = true"
        >
          <span slot="reference" style="cursor: help">
            <i
              class="el-icon-info"
              :style="{
                color: dataForm.isProductNoValid
                  ? 'black'
                  : gottenFocusAndBlurred
                  ? 'red'
                  : 'black'
              }"
              :class="
                dataForm.isProductNoValid
                  ? ''
                  : gottenFocusAndBlurred
                  ? 'jumper'
                  : ''
              "
            ></i>
            {{ $t('product_number') }}</span
          >
        </el-popconfirm>
      </span>
      <el-row>
        <el-col :span="fieldFocus == 0 ? 13 : 7">
          <el-form-item prop="productMainNo">
            <el-input
              v-model="dataForm.productMainNo"
              :placeholder="$t('product_main_no')"
              @blur.native.capture="blurEmit"
              @focus="setFieldFocus(0)"
              @input="computeProductNo"
              maxlength="20"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="fieldFocus == 1 ? 13 : 7" v-if="!excludeComponentNo">
          <el-form-item prop="productComponentNo">
            <el-input
              :placeholder="$t('product_component_no')"
              @blur.native.capture="blurEmit"
              @focus="setFieldFocus(1)"
              @input="computeProductNo"
              size="small"
              maxlength="20"
              style="padding: 0px"
              clearable
              v-model="dataForm.productComponentNo"
              class="prefix"
              ><i slot="prefix">/</i></el-input
            >
          </el-form-item>
        </el-col>
        <el-col :span="fieldFocus == 2 ? 10 : 4">
          <el-form-item prop="productVersionNo">
            <el-input
              :placeholder="$t('product_version_no')"
              @blur.native.capture="blurEmit"
              @focus="setFieldFocus(2)"
              @input="computeProductNo"
              size="small"
              maxlength="2"
              clearable
              v-model="dataForm.productVersionNo"
              class="prefix"
              ><i slot="prefix">-</i></el-input
            >
          </el-form-item>
        </el-col>
      </el-row>
      {{ dataForm.productNo }}
    </el-form-item>
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="50%"
      :title="$t('product_no_naming_rules')"
      append-to-body
    >
      <div class="dialog">
        <el-form>
          <el-form-item class="content">
            <div
              v-html="
                this.localizeParam(this.productNoValidation, 'instructions')
              "
            ></div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </el-form>
</template>

<script>
/**
 * props
 * excludeComponentNo:是否不显示双组份号输入框
 * factoryId【可空】: 查询的工厂id，如果空会找当前工厂的信息
 * isBase 【可空】
 * dataForm【非空】:
 * - productNo
 * - productMainNo
 * - productComponentNo
 * - productVersionNo
 *
 * emit
 * productNoChanged 会带一个更新的数据和isProductNoValid
 *
 * public function
 * validate, will trigger productNoChanged emit
 *
 */

import { getProductNoValidation } from '@/api/modules/productNoValidation'

export default {
  components: {},
  props: ['factoryId', 'dataForm', 'isBase', 'excludeComponentNo'],
  watch: {
    factoryId: {
      deep: true,
      handler(val) {
        this.getProductNoValidation(val)
      }
    },
    dataForm: {
      deep: true,
      handler(val) {
        this.computeProductNo()
      }
    }
  },
  data() {
    var validateMainNo = (rule, value, callback) => {
      // 产品主号不能为空
      if (value == undefined || value == null || value.trim() == '') {
        return callback(
          new Error(this.$t('e706', { x: this.$t('product_main_no') }))
        )
      }
      const regexStr = this.productNoValidation.productMainNoRegex
      if (regexStr) {
        const regex = new RegExp(regexStr, 'g')
        if (!value.match(regex)) {
          if (
            this.localizeParam(
              this.productNoValidation,
              'productMainNoDescription'
            )
          ) {
            return callback(
              new Error(
                this.localizeParam(
                  this.productNoValidation,
                  'productMainNoDescription'
                )
              )
            )
          }
          return callback(
            new Error(this.$t('e710', { x: this.$t('product_main_no') }))
          )
        }
      }
      return callback()
    }
    var validateComponentNo = (rule, value, callback) => {
      // 产品组份号可以为空
      if (value == undefined || value == null || value.trim() == '') {
        return callback()
      }
      const regexStr = this.productNoValidation.productComponentNoRegex
      if (regexStr) {
        const regex = new RegExp(regexStr, 'g')
        if (!value.match(regex)) {
          if (
            this.localizeParam(
              this.productNoValidation,
              'productComponentNoDescription'
            )
          ) {
            return callback(
              new Error(
                this.localizeParam(
                  this.productNoValidation,
                  'productComponentNoDescription'
                )
              )
            )
          }
          return callback(
            new Error(this.$t('e710', { x: this.$t('product_component_no') }))
          )
        }
      }
      return callback()
    }
    var validateVersionNo = (rule, value, callback) => {
      // 产品版本号可以为空
      if (value == undefined || value == null || value.trim() == '') {
        return callback()
      }
      const regexStr = this.productNoValidation.productVersionNoRegex
      if (regexStr) {
        const regex = new RegExp(regexStr, 'g')
        if (!value.match(regex)) {
          if (
            this.localizeParam(
              this.productNoValidation,
              'productVersionNoDescription'
            )
          ) {
            return callback(
              new Error(
                this.localizeParam(
                  this.productNoValidation,
                  'productVersionNoDescription'
                )
              )
            )
          }
          return callback(
            new Error(this.$t('e710', { x: this.$t('product_version_no') }))
          )
        }
      }
      return callback()
    }
    return {
      dialogVisible: false,
      dataLoading: false,
      productNoValidation: {},
      fieldFocus: 0,
      gottenFocus: false,
      gottenFocusAndBlurred: false,
      dataRule: {
        productMainNo: [
          {
            required: true,
            trigger: 'blur',
            validator: validateMainNo
          }
        ],
        productComponentNo: [
          {
            required: true,
            trigger: 'blur',
            validator: validateComponentNo
          }
        ],
        productVersionNo: [
          {
            required: true,
            trigger: 'blur',
            validator: validateVersionNo
          }
        ]
      }
    }
  },
  created() {
    this.getProductNoValidation(undefined)
    this.blurEmit()
  },
  methods: {
    validate() {
      this.blurEmit()
    },
    blurEmit() {
      this.dataForm.isProductNoValid = false
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataForm.isProductNoValid = true
        }
      })
      this.$emit('productNoChanged', this.dataForm)
      if (this.gottenFocus) {
        this.gottenFocusAndBlurred = true
      }
      this.$forceUpdate()
    },
    computeProductNo() {
      this.convertUpperCase()

      var computedProductNo = this.dataForm.productMainNo ? this.dataForm.productMainNo : ''
      if (this.dataForm.productComponentNo) {
        computedProductNo =
          computedProductNo + '/' + this.dataForm.productComponentNo
      }
      if (this.dataForm.productVersionNo) {
        computedProductNo =
          computedProductNo + '-' + this.dataForm.productVersionNo
      }
      if (this.isBase && this.productNoValidation.baseSuffix) {
        computedProductNo =
          computedProductNo + '-' + this.productNoValidation.baseSuffix
      }
      this.dataForm.productNo = computedProductNo
    },
    convertUpperCase() {
      if (this.dataForm.productMainNo) {
        this.dataForm.productMainNo = this.dataForm.productMainNo.toUpperCase()
      }
      if (this.dataForm.productComponentNo) {
        this.dataForm.productComponentNo =
          this.dataForm.productComponentNo.toUpperCase()
      }
      if (this.dataForm.productVersionNo) {
        this.dataForm.productVersionNo =
          this.dataForm.productVersionNo.toUpperCase()
      }
    },
    getProductNoValidation(factoryId) {
      getProductNoValidation({
        factoryId: factoryId ? factoryId : undefined
      }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.productNoValidation) {
            this.productNoValidation = data.productNoValidation
          }
        } else {
        }
      })
    },
    setFieldFocus(focus) {
      this.gottenFocus = true
      this.fieldFocus = focus
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog {
  margin-top: -20px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 20px 30px 0px 30px;
}
.content {
  overflow: auto;
  word-wrap: normal;
  max-height: 500px;
}
/deep/.el-input--prefix .el-input__inner {
  padding-left: 15px;
  padding-right: 10px;
}

.jumper {
  animation: jump-shaking 0.83s 3;
}

@keyframes jump-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateY(-4px);
  }
  35% {
    transform: translateY(-4px) rotate(17deg);
  }
  55% {
    transform: translateY(-4px) rotate(-17deg);
  }
  65% {
    transform: translateY(-4px) rotate(17deg);
  }
  75% {
    transform: translateY(-4px) rotate(-17deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
</style>