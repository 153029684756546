<template>
    <div class="materialInfo">
        <div class="operation">
            <el-button :style="{visibility: (isMetalPowder === '0' && sortNum === '1') || componentValue === '3' || ['2','3'].includes(recipeFlag) ? 'hidden' : ''}" @click="pasteFromSAP" :disabled="isDisabled">SAP</el-button>
            <el-button :style="{visibility: componentValue === '3' && !['2','3'].includes(recipeFlag) ? '' : 'hidden'}" @click="addBasePowder" :disabled="isDisabled">底粉</el-button>
            <el-button @click="deleteSelected" :disabled="isDisabled">删除</el-button>
        </div>
        <div class="component-info">
            <div class="component-info-item">
                <span>
                    <span v-if="isMetalPowder === '0'">
                        <span v-if="componentValue !== '3'">
                            <span v-if="sortNum === '1'">金属粉</span>
                            <span v-if="sortNum === '2'">底粉A</span>
                            <span v-if="sortNum === '3'">底粉B</span>
                        </span>
                    </span>
                    <span v-if="isMetalPowder === '1'">
                        <span v-if="componentValue === '2'">
                            <span v-if="sortNum === '2'">组份A</span>
                            <span v-if="sortNum === '3'">组份B</span>
                        </span>
                    </span>
                </span>
                <span>总重量: {{ totalWeight }} g</span>
                <span>余量: {{ residueWeight }} g</span>
            </div>
        </div>
        <el-table ref="selectionTable" :data="recipe.mater" border style="width: 100%;min-height: 400px;">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="编号" prop="materialNo" width="150">
                <template slot-scope="scope">
                    <div v-if="!scope.row.isEdit || scope.row.isProduct">
                        {{ scope.row.materialNo }}
                    </div>
                    <el-input v-model="scope.row.materialNo" placeholder="物料" size="small" v-else></el-input>
                    <span class="error_msg" v-if="scope.row.error == '1'">编号不能为空</span>
                    <span class="error_msg" v-if="scope.row.error == '2'">物料号不在库</span>
                    <span class="error_msg" v-if="scope.row.error == '4'">物料号重复</span>
                </template>
            </el-table-column>
            <el-table-column label="重量" prop="materialValue">
                <template slot-scope="scope">
                    <div v-if="!scope.row.isEdit">{{ scope.row.materialValue }}
                    </div>
                    <el-input v-model="scope.row.materialValue" placeholder="g" size="small" v-else></el-input>
                    <span class="error_msg" v-if="scope.row.error == '3'">重量须大于等于0.01</span>
                </template>
            </el-table-column>
            <!-- <el-table-column width="65">
                <template slot-scope="scope">
                    <el-button type="small" @click="btnClick(scope.row, scope.$index)" :disabled="isDisabled">补</el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <el-button class="addBtn" @click="addRows()" :disabled="isDisabled" style="margin-bottom: 20px;">+</el-button>
        <selectBasePowder v-if="dialogVisible" ref="selectBasePowder" @addData="addData" ></selectBasePowder>
    </div>
</template>
  
<script>
import { packagingSapRecipeData} from '@/api/modules/sampleorder'
import { multiply } from '@/utils/validate';
import selectBasePowder from './selectBasePowder'
export default {
    props:{
        recipe:{
            type: Object,
            default: {}
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        componentValue: {
            type: String,
            default: ''
        },
        isMetalPowder: {
            type: String,
            default: ''
        },
        recipeFlag: {
            type: String,
            default: ''
        },
        sortNum: {
            type: String,
            default: ''
        }
    },
    components: {
        selectBasePowder
    },
    computed: {
        totalWeight() {
            let total = 0;
            this.recipe.mater.forEach((item) => {
                if (!isNaN(Number(item.materialValue))) {
                    total = multiply(total, Number(item.materialValue));
                }
            });
            return parseFloat((total).toFixed(3));
        },
        residueWeight() {
            let total = 0;
            this.recipe.mater.forEach((item) => {
                if (!isNaN(Number(item.materialValue))) {
                    total = multiply(total, Number(item.materialValue));
                }
            });
            return parseFloat((1000 - total).toFixed(3));
        }
    },
    data() {
        return {
            dialogVisible: false,
        };
    },
    created() {
    },
    methods: {
        async  pasteFromSAP() {
            // 实现黏贴SAP的逻辑
            // 获取剪切板上的内容
            try {
                const clipboardData = await navigator.clipboard.readText();
                // 在这里可以对剪贴板上的内容进行处理
                packagingSapRecipeData({ recipeText: clipboardData }).then((res) =>{
                    if (res.data.materialInfoList && res.data.materialInfoList.length) {
                        this.recipe.mater = this.recipe.mater.concat(res.data.materialInfoList)
                    }
                });
            } catch (error) {
                console.error('无法读取剪贴板数据：', error);
            }

        },
        addBasePowder() {
            this.dialogVisible = true
            this.$nextTick(() => {
                this.$refs.selectBasePowder.init()
            })
        },
        addData(e) {
            var arr = JSON.parse(e)
            arr.map((v) => {
                this.recipe.mater.push({ materialNo: v.no, materialValue: '', isProduct: true, isEdit: true, productId: v.id});
            })
        },
        deleteSelected() {
            // 删除选中的行
            const selectedRows = this.$refs.selectionTable.selection;
            if (selectedRows.length > 0) {
                this.recipe.mater = this.recipe.mater.filter(
                    (item) => !selectedRows.includes(item)
                );
            }
        },
        // 添加新行
        addRows() {
            this.recipe.mater.push({ materialNo: '', materialValue: '', isEdit: true });
            console.log(this.recipe.mater);
        },
        // 补1000g的逻辑
        btnClick(row, index) {
            let total = 0;
            this.recipe.mater.map((e,i) => {
                if (i !== index) {
                    total = multiply(total, Number(e.materialValue));
                }
            })
            this.recipe.mater[index].materialValue = (1000 - total).toString()
        }
    },




};
</script>
<style lang="scss" scope>
.materialInfo{
    width: 350px;
    height: 500px;
    .operation {
        display: flex;
        justify-content: space-between;
        padding: 3px;
        border: 1px solid #DCDFE6;
        border-radius: 6px 6px 0 0;
    }

    .component-info {
        padding: 10px 3px;
        border-left: 1px solid #DCDFE6;
        border-right: 1px solid #DCDFE6;
    }

    .component-info-item {
        display: flex;
        justify-content: space-between;
    }

    .addBtn {
        // position: absolute;
        width: 350px;
        border-top: none;
        border-radius: 0;
    }

    .addBtn span {
        font-size: 16px;
    }

}
.error_msg {
    color: red;
    font-size: 10px;
}
</style>
  