import request from "@/utils/request";

const saasUrl = "/tiger-admin/product/productnovalidation";

export function getProductNoValidation (params) {
  return request({
      url: `${saasUrl}/getProductNoValidation`,
      method: 'get',
      params: params
  })
}