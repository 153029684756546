<template>
  <el-dialog
    title="重新分配"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="500px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="100px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="订单编号">
        <el-input
          v-model="dataForm.sampleOrderNo"
          disabled
          style="width: 80%"
        ></el-input>
      </el-form-item>
      <el-form-item label="打样员" prop="userId">
        <el-select
          v-model="dataForm.userId"
          placeholder="打样员"
          @change="changeUser"
        >
          <el-option
            v-for="item in userList"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="实验室名称" prop="laboratory">
        <el-input
          v-model="dataForm.laboratory"
          placeholder="实验室名称"
          size="small"
          style="width: 80%"
        ></el-input>
      </el-form-item>
      <el-form-item label="打样说明" prop="description">
        <el-input
          type="textarea"
          placeholder="打样说明"
          v-model="dataForm.description"
          maxlength="50"
          show-word-limit
          style="width: 80%"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { info, add, update } from "@/api/modules/sampleproduct";
import {
  UserList,
  getSampleUserInfo,
  updateSampleUser,
} from "@/api/modules/sampleorder";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      userList: [],
      dataForm: {
        id: 0,
        sampleNo: "",
        sampleOrderId: "",
        sampleOrderNo: "",
        picUrl: "",
        qrUrl: "",
        ralColorNo: "",
        gloss: "",
        glossValue: "",
        surface: "",
        factoryId: "",
        userId: "",
        laboratory: "",
        description: "",
        l: "",
        a: "",
        b: "",
        state: "",
        createBy: "",
        createDate: "",
        updateBy: "",
        updateDate: "",
        remarks: "",
        userName: "",
      },
      dataRule: {
        userId: [
          { required: true, message: "打样员不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    init(id, sampleOrderNo, factoryId) {
      this.dataForm.sampleOrderId = id || 0;
      this.dataForm.sampleOrderNo = sampleOrderNo;
      this.dataForm.factoryId = factoryId;
      this.visible = true;
      this.getUserList();
      getSampleUserInfo({ id: this.dataForm.sampleOrderId }).then(
        ({ data }) => {
          if (data && data.code === 0) {
            this.dataForm.userId = data.SampleUser.userId;
            this.dataForm.laboratory = data.SampleUser.laboratory;
            this.dataForm.description = data.SampleUser.description;
          }
        }
      );
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          var params = {
            sampleOrderId: this.dataForm.sampleOrderId,
            userId: this.dataForm.userId,
            userName: this.dataForm.userName,
            laboratory: this.dataForm.laboratory,
            description: this.dataForm.description,
          };
          var tick = !this.dataForm.sampleOrderId
            ? updateSampleUser(params)
            : updateSampleUser(params);
          tick.then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.dataLoading = false;
                  this.$emit("refreshDataList");
                },
              });
            } else {
              this.$message.error(data.msg);
              this.dataLoading = false;
            }
          });
        }
      });
    },
    close() {
      this.$refs["dataForm"].resetFields();
      this.visible = false;
    },
    //获取人员下拉列表
    getUserList() {
      var params = {
        id: this.dataForm.sampleOrderId,
        factoryId: this.dataForm.factoryId,
      };
      UserList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.userList = data.userList;
        }
      });
    },
    changeUser(val) {
      const user = this.userList.filter((item) => item.userId == val)[0];
      this.dataForm.userName = user.name;
    },
  },
};
</script>
