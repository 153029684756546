var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_c('sides-lip',{ref:"SidesLip",attrs:{"close-on-click-modal":false}},[_c('div',{staticStyle:{"margin-bottom":"20px"},attrs:{"slot":"soltForm"},slot:"soltForm"},[(_vm.dataList.length > 0 && _vm.dataCipeSize === 0)?_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.dataListLoading),expression:"dataListLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.dataList,"border":""},on:{"selection-change":_vm.selectionChangeHandle}},[_c('el-table-column',{attrs:{"prop":"materialNo","header-align":"center","align":"center","label":"材料编号"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.componentId != null || scope.row.productId != null
              )?_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.skipMaterial(scope.row.componentId, scope.row.productId)}}},[_vm._v(_vm._s(scope.row.materialNo))]):_vm._e(),(
                scope.row.componentId == null && scope.row.productId == null
              )?_c('label',[_vm._v(_vm._s(scope.row.materialNo))]):_vm._e()]}}],null,false,1455778703)}),_c('el-table-column',{attrs:{"prop":"materialName","header-align":"center","align":"center","label":"原料名称"}}),_c('el-table-column',{attrs:{"prop":"value","header-align":"center","align":"center","label":"计量值"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm._f("numFilter")(scope.row.value)))])]}}],null,false,3589391234)}),_c('el-table-column',{attrs:{"prop":"units","header-align":"center","align":"center","label":"单位"}}),_c('el-table-column',{attrs:{"prop":"rate","header-align":"center","align":"center","label":"计量百分比"}})],1):_vm._e(),(_vm.dataCipeSize != 0)?_c('div',[_c('p',[_vm._v("邦定工艺")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.dataCipeList,"border":"","span-method":_vm.objectSpanMethod}},[_c('el-table-column',{attrs:{"prop":"addStart","header-align":"center","align":"center","label":"工序"}}),_c('el-table-column',{attrs:{"prop":"data","header-align":"center","align":"center","label":"数据"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.componentId != null || scope.row.productId != null
              )?_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.skipMaterial(scope.row.componentId, scope.row.productId)}}},[_vm._v(_vm._s(scope.row.data))]):_vm._e(),(
                scope.row.componentId == null && scope.row.productId == null
              )?_c('label',[_vm._v(_vm._s(scope.row.data))]):_vm._e()]}}],null,false,1145010703)}),_c('el-table-column',{attrs:{"prop":"weight","header-align":"center","align":"center","label":"重量"}}),_c('el-table-column',{attrs:{"prop":"rate","header-align":"center","align":"center","label":"比例"}})],1)],1):_vm._e()],1),_c('div',{attrs:{"slot":"soltFooter"},slot:"soltFooter"},[_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.close()}}},[_vm._v("返回")])],1)])]),_c('material-recipe',{ref:"materialRecipe"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }