import request from '@/utils/request'

const url = '/tiger-admin/productattr/productattrvalue';

// 获取列表
export function list (params) {
  return request({
    url: '/productattr/productattrvalue/list',
    method: 'get',
    params: params
  })
}
// 获取属性值列表
export function selectAttrValueList (params) {
  return request({
    url: '/productattr/productattrvalue/selectAttrValueList',
    method: 'get',
    params: params
  })
}
// 获取子属性值列表
export function selectChildAttr (params) {
  return request({
    url: '/productattr/productattrvalue/selectChildAttr',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/productattr/productattrvalue/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/productattr/productattrvalue/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/productattr/productattrvalue/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/productattr/productattrvalue/delete',
    method: 'post',
    data: params
  })
}

// 逻辑删除
export function updateState (params) {
  return request({
    url: '/productattr/productattrvalue/updateState',
    method: 'post',
    data: params
  })
}

export function getAttrList (params) {
  return request({
    url: '/productattr/productattr/queryProductAttrNoList',
    method: 'get',
    params: params
  })
}

export function getProductAttrValue (params) {
    return request({
        url: `${url}/getProductAttrValue`,
        method: 'get',
        params: params
    })
}

export function getProductAttrValueByProNo (params) {
    return request({
        url: '/productattr/productattrvalue/getProductAttrValueByProNo',
        method: 'get',
        params: params
    })
}


export function getProductAttrValueByProNo1 (params) {
    return request({
        url: '/productattr/productattrvalue/getProductAttrValueByProNo1',
        method: 'get',
        params: params
    })
}

export function getTypeSurface (params) {
    return request({
        url: '/productattr/productattrvalue/getTypeSurface',
        method: 'get',
        params: params
    })
}

export function getSeriesPerformanceUse (params) {
    return request({
        url: '/productattr/productattrvalue/getSeriesPerformanceUse',
        method: 'get',
        params: params
    })
}

export function getproductAttrInfo (params) {
    return request({
        url: '/productattr/productattrvalue/getproductAttrInfo',
        method: 'get',
        params: params
    })
}

//设置同步
export function synProductAttrValue (params) {
  return request({
    url: '/productattr/productattrvalue/synProductAttrValue',
    method: 'post',
    data: params
  })
}