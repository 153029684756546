<template>
  <div class="app-container">
    <!--<el-dialog
            :title="!dataForm.id ? '查看配方' : '查看配方'"
            :close-on-click-modal="false"
            :visible.sync="recipeListVisible"
            append-to-body>-->
    <sides-lip ref="SidesLip" :close-on-click-modal="false">
      <div slot="soltForm" style="margin-bottom: 20px">
        <el-table
          :data="dataList"
          v-if="dataList.length > 0 && dataCipeSize === 0"
          border
          v-loading="dataListLoading"
          @selection-change="selectionChangeHandle"
          style="width: 100%"
        >
          <el-table-column
            prop="materialNo"
            header-align="center"
            align="center"
            label="材料编号"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                v-if="
                  scope.row.componentId != null || scope.row.productId != null
                "
                @click="
                  skipMaterial(scope.row.componentId, scope.row.productId)
                "
                >{{ scope.row.materialNo }}</el-link
              >
              <label
                v-if="
                  scope.row.componentId == null && scope.row.productId == null
                "
                >{{ scope.row.materialNo }}</label
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="materialName"
            header-align="center"
            align="center"
            label="原料名称"
          >
            <!--<template slot-scope="scope">-->
            <!--<el-link type="primary" v-if="scope.row.componentId!=null||scope.row.productId!=null" @click="skipMaterial(scope.row.componentId,scope.row.productId)" >{{scope.row.materialName}}</el-link>-->
            <!--<label v-if="scope.row.componentId==null&&scope.row.productId==null">{{scope.row.materialName}}</label>-->
            <!--</template>-->
          </el-table-column>

          <el-table-column
            prop="value"
            header-align="center"
            align="center"
            label="计量值"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.value | numFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="units"
            header-align="center"
            align="center"
            label="单位"
          >
          </el-table-column>
          <el-table-column
            prop="rate"
            header-align="center"
            align="center"
            label="计量百分比"
          >
          </el-table-column>
        </el-table>
        <!--<el-pagination
                size="small"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>-->

        <div v-if="dataCipeSize != 0">
          <p>邦定工艺</p>
          <el-table
            :data="dataCipeList"
            border
            :span-method="objectSpanMethod"
            style="width: 100%"
          >
            <el-table-column
              prop="addStart"
              header-align="center"
              align="center"
              label="工序"
            >
            </el-table-column>
            <el-table-column
              prop="data"
              header-align="center"
              align="center"
              label="数据"
            >
            <template slot-scope="scope">
              <el-link
                type="primary"
                v-if="
                  scope.row.componentId != null || scope.row.productId != null
                "
                @click="
                  skipMaterial(scope.row.componentId, scope.row.productId)
                "
                >{{ scope.row.data }}</el-link
              >
              <label
                v-if="
                  scope.row.componentId == null && scope.row.productId == null
                "
                >{{ scope.row.data }}</label
              >
            </template>
            </el-table-column>
            <el-table-column
              prop="weight"
              header-align="center"
              align="center"
              label="重量"
            >
            </el-table-column>
            <el-table-column
              prop="rate"
              header-align="center"
              align="center"
              label="比例"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="soltFooter">
        <span slot="footer" class="dialog-footer">
          <el-button @click="close()" size="small">返回</el-button>
        </span>
      </div>
      <!--</el-dialog>-->
    </sides-lip>
    <!--材料跳转-->
    <material-recipe ref="materialRecipe"></material-recipe>
  </div>
</template>

<script>
import {
  listAll,
  listAllSCipe
} from '@/api/modules/samplerecipe'
import MaterialRecipe from './materialRecipe'
import SidesLip from '@/components/SidesLip'
export default {
  data() {
    return {
      dataForm: {
        recipeOrderId: '',
        daterange: [],
        sampleProductId: '',
        category: '',
        sampleType: '',
        componentId: '', //组别id
        productId: '' //产品id
      },
      cellList: [], // 单元格数组
      count: null, // 计数
      dataList: [],
      dataCipeList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataCipeSize: 0,
      dataListLoading: false,
      dataListSelections: [],
      recipeListVisible: false,
      materialRecipeVisible: false
    }
  },
  components: {
    MaterialRecipe,
    SidesLip
  },

  //写个过滤器，保留小数点后两位
  filters: {
    numFilter(value) {
      let realVal = ''
      if (!isNaN(value) && value !== '') {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(3)
      } else {
        realVal = '--'
      }
      return realVal
    }
  },
  methods: {
    close() {
      this.$refs.SidesLip.sideClose()
    },
    //点击超链接文字，触发跳转页面
    skipMaterial(componentId, productId) {
      this.materialRecipeVisible = true
      this.$nextTick(() => {
        this.$refs.materialRecipe.init(componentId, productId)
      })
    },
    init(id, category, sampleType) {
      this.dataForm.sampleProductId = id
      this.$refs.SidesLip.init(
        this.dataForm.id ? '查看配方' : '查看配方',
        '800px',
        'block'
      )
      this.dataForm.category = category
      this.dataForm.sampleType = sampleType
      this.recipeListVisible = true
      ;(this.dataCipeList = []),
        (this.dataCipeSize = 0),
        (this.cellList = []), // 单元格数组
        (this.count = null), // 计数
        this.getDataList()
      this.listAllSCipe()
    },

    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        sampleProductId: this.dataForm.sampleProductId
      }
      listAll(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    listAllSCipe() {
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        sampleProductId: this.dataForm.sampleProductId
      }
      listAllSCipe(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataCipeList = data.specialrecipeDatas
          this.dataCipeSize = data.totalCount
          this.computeCell(this.dataCipeList)
        } else {
          this.dataCipeList = []
          this.dataCipeSize = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
      this.listAllSCipe()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
      this.listAllSCipe()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    computeCell(tableBody) {
      // 循环遍历表体数据
      for (let i = 0; i < tableBody.length; i++) {
        if (i == 0) {
          // 先设置第一项
          this.cellList.push(1) // 初为1，若下一项和此项相同，就往cellList数组中追加0
          this.count = 0 // 初始计数为0
        } else {
          // 判断当前项与上项的设备类别是否相同，因为是合并这一列的单元格
          if (tableBody[i].addStart == tableBody[i - 1].addStart) {
            // 如果相等
            this.cellList[this.count] += 1 // 增加计数
            this.cellList.push(0) // 相等就往cellList数组中追加0
          } else {
            this.cellList.push(1) // 不等就往cellList数组中追加1
            this.count = i // 将索引赋值为计数
          }
        }
      }
    },
    // 第2步，将计算好的结果返回给el-table，这样的话表格就会根据这个结果做对应合并列渲染
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 给第二列做单元格合并。0是第一列，1是第二列。
      if (columnIndex === 0) {
        const rowCell = this.cellList[rowIndex]
        if (rowCell > 0) {
          const colCell = 1
          return {
            rowspan: rowCell,
            colspan: colCell
          }
        } else {
          // 清除原有的单元格，必须要加，否则就会出现单元格会被横着挤到后面了！！！
          // 本例中数据是写死的不会出现，数据若是动态后端获取的，就会出现了！！！
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  }
}
</script>
